import styled from "styled-components";
import { themeColors } from "../../../../constants/constants";
import { mapKatastarKeyToDisplayString } from "../../../../utils/translate";
import { CardContainer, CardWrapper, Row } from "../../style";
import { KatastarCard, KatastarTitle } from "../style";
import KatakomTable from "../../../shared/KatakomTable";
import { pdf } from "@react-pdf/renderer";
import moment from "moment";
import GenerateObjekatPDF from "../../../pdfs/ObjekatPDF";
import GenerateDeoObjekatPDF from "../../../pdfs/DeoObjektaPDF";
import GenerateParcelePDF from "../../../pdfs/ParcelePDF";
import api from "../../../../services/api";
import React from "react";
import KatakomModal from "../../../shared/KatakomModal";
import { PreviewPDFIFrame } from "./style";
import KatakomButton from "../../../shared/KatakomButton";
import useMobileCheck from "../../../../hooks/useMobileChecker";
import Loader from "../../../shared/Loader";

export const RenderLicaData = (props: any) => {
    const [pdfSrc, setPdfSrc] = React.useState<string | null>(null);
    const [previewPDF, setPreviewPDF] = React.useState<boolean>(false);
    const isMobile = useMobileCheck();
    const liceData = Array.isArray(props.data?.Lica?.Lice)
        ? props.data?.Lica?.Lice
        : props.data?.Lica?.Lice
        ? [props.data?.Lica?.Lice]
        : [];
    const [loading, setLoading] = React.useState<boolean>(false);

    const downloadParcelaPdf = async (item: any, download?: boolean) => {
        if (!download) {
            const blob = await pdf(<GenerateParcelePDF pdfData={item} />).toBlob();
            // Create a URL for the blob
            const url = URL.createObjectURL(blob);

            setPreviewPDF(true);
            setPdfSrc(url);
        } else {
            const blob = await pdf(<GenerateParcelePDF pdfData={item} />).toBlob();
            // Create a URL for the blob
            const url = URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            const date = moment().format("YYYYMMDD");
            a.download = `${date}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);

            // @ts-ignore
            if (window?.ReactNativeWebView) {
                const base64Data = await new Response(blob).arrayBuffer();
                const base64String = btoa(new Uint8Array(base64Data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
                const downloadInfo = {
                    message: "DownloadFile",
                    base64Data: base64String,
                    fileName: `${date}.pdf`,
                };

                // @ts-ignore
                window.ReactNativeWebView.postMessage(JSON.stringify(downloadInfo));
            }
        }
    };

    const downloadObjekatPdf = async (parcela: any, deoParcele: any, objekat: any, download?: boolean) => {
        if (!download) {
            const blob = await pdf(<GenerateObjekatPDF pdfData={parcela} deoParceleData={deoParcele} objekat={objekat} />).toBlob();
            const url = URL.createObjectURL(blob);

            setPreviewPDF(true);
            setPdfSrc(url);
        } else {
            const blob = await pdf(<GenerateObjekatPDF pdfData={parcela} deoParceleData={deoParcele} objekat={objekat} />).toBlob();
            const url = URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            const date = moment().format("YYYYMMDD");
            a.download = `${date}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);

            // @ts-ignore
            if (window?.ReactNativeWebView) {
                const base64Data = await new Response(blob).arrayBuffer();
                const base64String = btoa(new Uint8Array(base64Data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
                const downloadInfo = {
                    message: "DownloadFile",
                    base64Data: base64String,
                    fileName: `${date}.pdf`,
                };

                // @ts-ignore
                window.ReactNativeWebView.postMessage(JSON.stringify(downloadInfo));
            }
        }
    };

    const downloadDeoObjekatPdf = async (parcela: any, deoParcele: any, objekat: any, deoObjekta: any, download?: boolean) => {
        if (!download) {
            const blob = await pdf(
                <GenerateDeoObjekatPDF pdfData={parcela} deoParceleData={deoParcele} objekat={objekat} deoObjekta={deoObjekta} />
            ).toBlob();
            const url = URL.createObjectURL(blob);

            setPreviewPDF(true);
            setPdfSrc(url);
        } else {
            const blob = await pdf(
                <GenerateDeoObjekatPDF pdfData={parcela} deoParceleData={deoParcele} objekat={objekat} deoObjekta={deoObjekta} />
            ).toBlob();
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            const date = moment().format("YYYYMMDD");
            a.download = `${date}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);

            // @ts-ignore
            if (window?.ReactNativeWebView) {
                const base64Data = await new Response(blob).arrayBuffer();
                const base64String = btoa(new Uint8Array(base64Data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
                const downloadInfo = {
                    message: "DownloadFile",
                    base64Data: base64String,
                    fileName: `${date}.pdf`,
                };

                // @ts-ignore
                window.ReactNativeWebView.postMessage(JSON.stringify(downloadInfo));
            }
        }
    };

    const fatchData = async (koid: string | number, brojParcele: string | number, podbrojParcele: string | number) => {
        setLoading(true);
        try {
            let queryParams: any = {};

            if (koid !== "") {
                queryParams.koid = koid;
            }

            if (brojParcele !== "") {
                queryParams.brojParcele = brojParcele;
            }

            if (podbrojParcele !== "") {
                queryParams.podbrojParcele = podbrojParcele;
            } else {
                queryParams.podbrojParcele = "0";
            }

            const res = await api.get(`api/v1/pretraga-parcele-po-broju-parcele`, queryParams);
            setLoading(false);
            return res;
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    };

    const data = liceData.map((lice: any) => {
        const nepokretnost = lice?.Nepokretnosti?.Nepokretnost;
        const nepokretnostArray = Array.isArray(nepokretnost)
            ? nepokretnost.filter((item) => item !== undefined)
            : nepokretnost
            ? [nepokretnost]
            : [];

        return {
            ...lice,
            Nepokretnosti: {
                Nepokretnost: nepokretnostArray,
            },
        };
    });
    return (
        <KatastarDataSection>
            {loading && <Loader />}

            <KatakomModal isOpen={previewPDF} setIsOpen={setPreviewPDF}>
                <PreviewPDFIFrame>
                    {pdfSrc && (
                        <iframe
                            src={pdfSrc}
                            title="PDF Preview"
                            style={{ width: "100%", height: "80vh", border: "1px solid #ccc" }}
                        ></iframe>
                    )}
                    <Row style={{ justifyContent: "center" }}>
                        <KatakomButton
                            label={"Затвори"}
                            onClick={() => {
                                setPreviewPDF(false);
                            }}
                        ></KatakomButton>
                    </Row>
                </PreviewPDFIFrame>
            </KatakomModal>
            {data?.map((lice: any) => {
                return (
                    <CardWrapper>
                        <KatastarCard>
                            <CardContainer>
                                <KatastarTitle style={{ fontSize: isMobile ? "14px" : "16px" }}>лица</KatastarTitle>
                            </CardContainer>
                            <KatastarDataWrapper>
                                {Object.entries(lice).map(([key, option]) =>
                                    typeof option !== "object" && mapKatastarKeyToDisplayString(key) ? (
                                        <KatastarDataComponent key={key}>
                                            <Key>{mapKatastarKeyToDisplayString(key)}</Key>
                                            <Value>
                                                {/*@ts-ignore */}
                                                {option}
                                            </Value>
                                        </KatastarDataComponent>
                                    ) : null
                                )}
                            </KatastarDataWrapper>

                            <KatastarTitle
                                style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px", fontSize: isMobile ? "14px" : "16px" }}
                            >
                                непокретности изабраног лица
                            </KatastarTitle>

                            <LiceTable>
                                <div style={{ margin: "15px" }}>
                                    <KatakomTable
                                        columns={[
                                            // { value: "redniBroj", label: "Редни број" },
                                            { value: "Opstina", label: "Општина" },
                                            { value: "KatastarskaOpstina", label: "КО" },
                                            { value: "NepokretnostVrsta", label: "Врста непокретности" },
                                            { value: "VrstaPrava", label: "Врста права" },
                                            { value: "OblikSvojine", label: "Облик својине" },
                                            { value: "ObimPrava", label: "Обим права" },
                                            {
                                                value: "katastarDownload",
                                                label: "ПДФ",
                                                func: async (item, download) => {
                                                    if (item.NepokretnostVrsta === "Парцела") {
                                                        const resPretragaPoBrojuParcele = await fatchData(
                                                            item.KatastarskaOpstinaID,
                                                            item.Parcela?.BrParc,
                                                            item.Parcela?.PodBrParc
                                                        );
                                                        downloadParcelaPdf(resPretragaPoBrojuParcele?.data, download);
                                                    } else if (item.NepokretnostVrsta === "Објекат") {
                                                        const resPretragaPoBrojuParcele = await fatchData(
                                                            item.KatastarskaOpstinaID,
                                                            item.Objekat?.BrParc,
                                                            item.Objekat?.PodBrParc
                                                        );
                                                        const deoParcele = Array.isArray(
                                                            resPretragaPoBrojuParcele?.data.Parcele.Parcela.DeloviParcele.DeoParcele
                                                        )
                                                            ? resPretragaPoBrojuParcele?.data.Parcele.Parcela.DeloviParcele.DeoParcele.find(
                                                                  (deoParcele: any) => deoParcele.BrDelaParc === item.Objekat.BrDelaParc
                                                              )
                                                            : resPretragaPoBrojuParcele?.data.Parcele.Parcela.DeloviParcele.DeoParcele;

                                                        const objekat = Array.isArray(deoParcele.Objekti.Objekat)
                                                            ? deoParcele.Objekti.Objekat.find((obj: any) => obj.ID === item.ID)
                                                            : deoParcele.Objekti.Objekat;

                                                        downloadObjekatPdf(resPretragaPoBrojuParcele?.data, deoParcele, objekat, download);
                                                    } else if (item.NepokretnostVrsta === "Посебан део објекта") {
                                                        const resPretragaPoBrojuParcele = await fatchData(
                                                            item.KatastarskaOpstinaID,
                                                            item.ObjekatDeo?.BrParc,
                                                            item.ObjekatDeo?.PodBrParc
                                                        );
                                                        const deoParcele = Array.isArray(
                                                            resPretragaPoBrojuParcele?.data.Parcele.Parcela.DeloviParcele.DeoParcele
                                                        )
                                                            ? resPretragaPoBrojuParcele?.data.Parcele.Parcela.DeloviParcele.DeoParcele.find(
                                                                  (deoParcele: any) => deoParcele.BrDelaParc === item.ObjekatDeo.BrDelaParc
                                                              )
                                                            : resPretragaPoBrojuParcele?.data.Parcele.Parcela.DeloviParcele.DeoParcele;

                                                        const findObjekatWithID: any = (objekti: any, id: any) => {
                                                            const objekatArray = Array.isArray(objekti.Objekat)
                                                                ? objekti.Objekat
                                                                : [objekti.Objekat];

                                                            const foundObjekat = objekatArray.find((objekat: any) => {
                                                                const objekatDeoArray = Array.isArray(objekat.DeloviObjekta.ObjekatDeo)
                                                                    ? objekat.DeloviObjekta.ObjekatDeo
                                                                    : [objekat.DeloviObjekta.ObjekatDeo];

                                                                return objekatDeoArray.some((deo: any) => deo.ID === id);
                                                            });

                                                            return foundObjekat || null;
                                                        };

                                                        const objekat = findObjekatWithID(deoParcele.Objekti, item.ID);

                                                        const deoObjekta = Array.isArray(objekat.DeloviObjekta.ObjekatDeo)
                                                            ? objekat.DeloviObjekta.ObjekatDeo.find((deoObj: any) => deoObj.ID === item.ID)
                                                            : objekat.DeloviObjekta.ObjekatDeo;

                                                        downloadDeoObjekatPdf(
                                                            resPretragaPoBrojuParcele?.data,
                                                            deoParcele,
                                                            objekat,
                                                            deoObjekta,
                                                            download
                                                        );
                                                    }
                                                },
                                            },
                                        ]}
                                        data={lice.Nepokretnosti.Nepokretnost}
                                    />
                                </div>
                            </LiceTable>
                        </KatastarCard>
                    </CardWrapper>
                );
            })}
        </KatastarDataSection>
    );
};

export const KatastarDataSection = styled.div`
    display: flex;
    color: ${themeColors.secondary};
    flex-direction: column;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
`;

const KatastarDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 16px 20px;
`;

const KatastarDataComponent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 5px;
    margin-left: 20px;
    line-height: 26px;
`;

const Key = styled.span`
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
    text-align: left;
    width: 180px;
    @media screen and (max-width: 900px) {
        width: 150px;
    }
`;

const Value = styled.span`
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    @media screen and (max-width: 900px) {
        width: 120px;
    }
`;

const LiceTable = styled.div`
    width: 100%;
    margin: 20px;
`;
