import React, { FC } from "react";
import styled from "styled-components";
import { themeColors } from "../../constants/constants";

import useMobileCheck from "../../hooks/useMobileChecker";

interface ButtonProps {
    label: string;
    onClick: (e: any) => void;
    size?: "s" | "m" | "l" | "xl";
    disabled?: boolean;
    style?: React.CSSProperties;
    type?: string;
    accept?: string;
    tooltipMessage?: string;
    theme?: "primary" | "secondary" | "tertiary";
}

export const Tooltip = styled.div`
    position: absolute;
    width: 230px;
    background: ${themeColors.tertiary};
    color: white;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    z-index: 1;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
    box-shadow: 0px 4px 4px 0px #00000040;
    text-align: left;

    /* &:after {
        content: "";
        position: absolute;
        border-style: solid;
        border-width: 6px;
        border-color: transparent transparent ${themeColors.tertiary} transparent;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
    } */
`;

const ButtonWrapper = styled.button<ButtonProps>`
    font-size: 16px;
    font-weight: 500;
    width: ${(props) => getButtonSize(props.size)};
    height: ${(props) => (props.theme === "tertiary" ? "42px" : "52px")};
    color: ${(props) => {
        if (props.disabled) return themeColors.primary;
        switch (props.theme) {
            case "primary":
                return themeColors.primary;
            case "secondary":
                return themeColors.secondary;
            case "tertiary":
                return themeColors.secondary;
            default:
                return themeColors.primary;
        }
    }};
    background: ${(props) => {
        if (props.disabled) return themeColors.tertiary;
        switch (props.theme) {
            case "primary":
                return themeColors.secondary;
            case "secondary":
                return themeColors.primary;
            case "tertiary":
                return themeColors.quaternary;
            default:
                return themeColors.secondary;
        }
    }};
    border: ${(props) => {
        if (props.disabled) return "none";

        switch (props.theme) {
            case "primary":
                return "none";
            case "secondary":
                return `2px solid ${themeColors.secondary}`;
            default:
                return "none";
        }
    }};
    border-radius: 4px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    outline: none;
    box-shadow: ${(props) => (props.theme === "tertiary" ? "none" : "5px 5px 10px 0px #00000040;")};
    position: relative;
    transition: background-color 0.3s ease;

    &:not(:disabled):hover,
    &:focus {
        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            border-radius: 5px;
            background-color: ${themeColors.quaternary};
            transition: all 0.3s ease-in-out;
            transform: translate(-50%, -50%);
            animation: fillButton 0.3s linear;
        }
    }

    @media screen and (max-width: 900px) {
        &:not(:disabled):hover {
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 0;
                height: 0;
                opacity: 0;
                border-radius: none;
                background-color: transparent;
                transition: none;
                transform: none;
                animation: none;
            }
        }
        &:focus {
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                opacity: 0.5;
                border-radius: 5px;
                background-color: ${themeColors.quaternary};
                transition: all 0.3s ease-in-out;
                transform: translate(-50%, -50%);
                animation: fillButton 0.3s linear;
            }
        }
    }

    @keyframes fillButton {
        0% {
            width: 0;
            height: 0;
            opacity: 0;
        }
        100% {
            width: 100%;
            height: 100%;
        }
    }

    &:hover ${Tooltip} {
        opacity: 1;
    }
`;

const getButtonSize = (size?: string) => {
    switch (size) {
        case "s":
            return "118px";
        case "m":
            return "140px";
        case "l":
            return "200px";
        case "xl":
            return "250px";
        default:
            return "140px"; // Default width if size is not provided or invalid
    }
};

const KatakomButton: FC<ButtonProps> = ({ label, disabled, onClick, size, style, tooltipMessage, theme }) => {
    const inputSize = getButtonSize(size);
    const explanation = tooltipMessage || "Сва обавезна поља морају бити попуњена!";
    const explanationPieces = explanation.split(";");
    const isMobile = useMobileCheck();

    return (
        <ButtonWrapper
            onClick={
                isMobile
                    ? () => {}
                    : (e) => {
                          if (!disabled) {
                              onClick(e);
                          }
                      }
            }
            onTouchEnd={
                isMobile
                    ? (e) => {
                          if (!disabled) {
                              onClick(e);
                          }
                      }
                    : () => {}
            }
            disabled={disabled}
            style={{ ...style, width: inputSize }}
            label={label}
            theme={theme}
        >
            {label}
            {disabled && (
                <Tooltip>
                    {explanationPieces.map((piece, index) => (
                        <>
                            {" "}
                            <span key={index}>{piece}</span>
                            <br />
                        </>
                    ))}
                </Tooltip>
            )}
        </ButtonWrapper>
    );
};

export default KatakomButton;
