import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import KatakomSelect, { SelectOption } from "../../../../shared/KatakomSelect";
import KatakomInput from "../../../../shared/KatakomInput";
import { ChangeEvent } from "react";
import KatakomButton from "../../../../shared/KatakomButton";
import { katastarskeOpstineOptions, vrstaNepokretnostiOptions } from "../../../../../register/register";
import KatakomModal from "../../../../shared/KatakomModal";
import { VrstaNepokretnosti } from "../../../../../enums";
import { ModalContent, Row } from "../../../style";
import { InfoMessageModal } from "../../../../customComponent/InfoMessageModal";
import { AssetsAdvokati, addOrUpdateNepokretnostAdvokati } from "../../../../../store/CaseAdvokatiReducer";
import { defaultAssetsAdvokati } from "../../../../../register/defaultValues";
import { checkForAttributeWithValue } from "../../../../../utils/utils";
import api from "../../../../../services/api";
import useMobileCheck from "../../../../../hooks/useMobileChecker";
import { SelectTable } from "../../../newCaseCommon/SelectTable";

interface Props {
    closeModal: () => void;
    nepokretnostId?: number;
    setEditingNepokretnost: (id: AssetsAdvokati | null) => void;
    modalState?: boolean;
}

const AssetModalComponentAdvokati: FC<Props> = ({ closeModal, nepokretnostId, setEditingNepokretnost, modalState }) => {
    const dispatch: AppDispatch = useAppDispatch();
    const nepokretnostKN = useSelector((state: RootState) => state.caseAdvokati.nepokretnostKN);
    const slucaj = useSelector((state: RootState) => state.caseAdvokati.basicInfo);
    const [disabled, setDisabled] = React.useState<boolean>(true);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [firstTimeEdit, setFirstTimeEdit] = React.useState<boolean>(true);
    const [validatingParcelaDisabled, setValidatingParcelaDisabled] = React.useState<boolean>(false);
    const [formData, setFormData] = useState<AssetsAdvokati>(defaultAssetsAdvokati);
    const [showSelectTable, setShowSelectTable] = useState<boolean>(false);
    const [showSelectTablePosebniDeo, setShowSelectTablePosebniDeo] = useState<boolean>(false);
    const [tableData, setTableData] = useState([]);

    const isMobile = useMobileCheck();

    useEffect(() => {
        if (nepokretnostId === undefined || (nepokretnostId !== undefined && !firstTimeEdit)) {
            setFormData({ ...defaultAssetsAdvokati, tip: formData.tip, id: nepokretnostId });
        }
        if (firstTimeEdit && formData.tip !== VrstaNepokretnosti.PARCELA) {
            setFirstTimeEdit(false);
        }
    }, [formData.tip]);

    useEffect(() => {
        if (nepokretnostId !== undefined) {
            const existingNepokretnost = nepokretnostKN.find((nep) => nep.id === nepokretnostId);
            if (existingNepokretnost) {
                setFormData(existingNepokretnost);

                if (
                    existingNepokretnost.tip !== VrstaNepokretnosti.OBJEKAT &&
                    existingNepokretnost.tip !== VrstaNepokretnosti.POSEBAN_DEO
                ) {
                    setFormData((prevData) => ({
                        ...prevData,
                        brojObjekta: "",
                        evidencijskiBroj: "",
                    }));
                } else if (existingNepokretnost.tip === VrstaNepokretnosti.POSEBAN_DEO) {
                    setFormData((prevData) => ({
                        ...prevData,
                        brojObjekta: existingNepokretnost.brojObjekta || "",
                    }));
                }
            }
        } else {
            setFormData(defaultAssetsAdvokati);
        }
    }, [nepokretnostId, nepokretnostKN]);

    useEffect(() => {
        setDisabled(isDisabled());
    }, [nepokretnostKN, formData]);

    useEffect(() => {
        if (modalState && !isMobile) {
            const select = document.getElementById("AssetModalComponentAdvoakti");
            select?.focus();
        }
    }, [modalState]);

    const isDisabled = (): boolean => {
        if (Object.values(formData).length === 0) {
            return true;
        }

        if (formData.tip === VrstaNepokretnosti.PARCELA && (!formData.koId || !formData?.brojParcele)) {
            return true;
        }

        if (formData.tip === VrstaNepokretnosti.OBJEKAT && (!formData.koId || !formData.brojParcele || !formData.brojObjekta)) {
            return true;
        }

        if (
            formData.tip === VrstaNepokretnosti.POSEBAN_DEO &&
            (!formData.koId || !formData.brojParcele || !formData.brojObjekta || !formData.evidencijskiBroj)
        ) {
            return true;
        }
        return false;
    };

    const handleSave = async () => {
        if (formData.tip !== VrstaNepokretnosti.OBJEKAT && formData.tip !== VrstaNepokretnosti.POSEBAN_DEO) {
            formData.brojObjekta = "";
        }

        if (formData.tip !== VrstaNepokretnosti.POSEBAN_DEO) {
            formData.evidencijskiBroj = "";
        }

        let parcelaValid = true;

        if (slucaj.vrstaIspraveId != "6125") {
            parcelaValid = await validateParcelaExists();
            setValidatingParcelaDisabled(false);
        }

        if (parcelaValid) {
            dispatch(addOrUpdateNepokretnostAdvokati(formData));
            setEditingNepokretnost(null);
            closeModal();
        } else {
            setOpenModal(true);
        }
    };

    const handleClose = () => {
        setEditingNepokretnost(null);
        closeModal();
    };

    const validateParcelaExists = async () => {
        setValidatingParcelaDisabled(true);
        let queryParams: any = {};

        if (formData.koId !== "") {
            queryParams.koid = formData.koId;
        }

        if (formData.brojParcele !== "") {
            queryParams.brojParcele = formData.brojParcele;
        }

        if (formData.podBrojParcele !== "") {
            queryParams.podbrojParcele = formData.podBrojParcele;
        } else {
            queryParams.podbrojParcele = "0";
        }

        try {
            const res = await api.get(`api/v1/pretraga-parcele-po-broju-parcele`, queryParams);
            if (res?.data?.Parcele?.Parcela?.ID || res?.data?.Parcele?.Parcela?.length > 0) {
                if (formData.brojObjekta && formData.brojObjekta !== "0") {
                    const brojObjektaExists = checkForAttributeWithValue(res?.data, formData.brojObjekta, "BrDelaParc");

                    if (brojObjektaExists) {
                        return true;
                    } else {
                        return false;
                    }
                }

                if (formData.evidencijskiBroj && formData.evidencijskiBroj !== "0") {
                    const evidencijskiBroj = checkForAttributeWithValue(res?.data, formData.evidencijskiBroj, "EvidencijskiBroj");

                    if (evidencijskiBroj) {
                        return true;
                    } else {
                        return false;
                    }
                }

                return true;
            } else {
                return false;
            }
        } catch (e) {
            setValidatingParcelaDisabled(false);
            setOpenModal(true);
            return false;
        }
    };

    useEffect(() => {
        setShowSelectTable(false);
        setShowSelectTablePosebniDeo(false);
    }, [formData.tip, formData.koId]);

    const showSelect = async () => {
        const selectData = await fatchSelectData();
        setTableData(selectData);

        if (formData.koId && formData.brojParcele) {
            setShowSelectTablePosebniDeo(false);
            setShowSelectTable(true);
        }
    };

    const showSelectPosebniDeo = async () => {
        const selectPosebanDeoData = await fatchSelectPosebanDeoData();

        setTableData(selectPosebanDeoData);

        if (formData.koId && formData.brojParcele) {
            setShowSelectTable(false);
            setShowSelectTablePosebniDeo(true);
        }
    };

    const fatchSelectData = async () => {
        let queryParams: any = {};

        if (formData.koId !== "") {
            queryParams.koid = formData.koId;
        }

        if (formData.brojParcele !== "") {
            queryParams.brojParcele = formData.brojParcele;
        }
        if (formData.podBrojParcele !== "") {
            queryParams.podbrojParcele = formData.podBrojParcele;
        } else {
            queryParams.podbrojParcele = "0";
        }

        let res = await api.get(`api/v1/pretraga-objekta-na-parceli`, queryParams);
        return res?.data;
    };

    const fatchSelectPosebanDeoData = async () => {
        let queryParams: any = {};

        if (formData.koId !== "") {
            queryParams.koid = formData.koId;
        }

        if (formData.brojParcele !== "") {
            queryParams.brojParcele = formData.brojParcele;
        }

        if (formData.podBrojParcele !== "") {
            queryParams.podbrojParcele = formData.podBrojParcele;
        } else {
            queryParams.podbrojParcele = "0";
        }

        if (formData.brojObjekta !== "") {
            queryParams.brojObjekta = formData.brojObjekta;
        }

        let res = await api.get(`api/v1/pretraga-posebnih-delova-objekata-na-parceli`, queryParams);
        return res?.data;
    };

    return (
        <ModalContent id="AssetModalComponentAdvoakti">
            <KatakomModal isOpen={openModal} setIsOpen={setOpenModal}>
                <InfoMessageModal
                    closeModal={() => {
                        setOpenModal(false);
                    }}
                    message="Парцела са унетим подацима не постоји у Катастру."
                />
            </KatakomModal>
            <Row>
                <KatakomSelect
                    options={vrstaNepokretnostiOptions}
                    value={formData.tip || ""}
                    name="tip"
                    size="xl"
                    label="Врста непокретности*"
                    error={false}
                    onChange={(option: SelectOption) => setFormData({ ...formData, tip: option.value })}
                />
                <KatakomSelect
                    options={katastarskeOpstineOptions}
                    value={formData.koId || ""}
                    name="koId"
                    size="xl"
                    label="Општина - Катастарска општина*"
                    error={false}
                    onChange={(option: SelectOption) => {
                        const opstinaNaziv = option.value.split("_")[0];
                        const koNaziv = option.value.split("_")[1];
                        const opstinaId = option.value.split("_")[2];
                        const koId = option.value.split("_")[3];

                        setFormData({ ...formData, opstinaNaziv, koNaziv, opstinaId, koId });
                    }}
                    style={{ margin: "0 0 0 30px" }}
                />
            </Row>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.brojParcele || ""}
                    name="brojParcele"
                    label="Број парцеле*"
                    size="s"
                    onFocus={() => {
                        setShowSelectTable(false);
                        setShowSelectTablePosebniDeo(false);
                    }}
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, brojParcele: e.target.value })}
                    style={{ margin: "0 50px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.podBrojParcele || ""}
                    name="podBrojParcele"
                    label="Подброј парцеле"
                    size="s"
                    onFocus={() => {
                        setShowSelectTable(false);
                        setShowSelectTablePosebniDeo(false);
                    }}
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, podBrojParcele: e.target.value })}
                    style={{ margin: "0 30px 0 0" }}
                />
                {formData.tip !== VrstaNepokretnosti.POSEBAN_DEO ? (
                    <KatakomInput
                        type="text"
                        value={formData.udeo || ""}
                        name="udeo"
                        label="Удео"
                        onFocus={() => {
                            setShowSelectTable(false);
                            setShowSelectTablePosebniDeo(false);
                        }}
                        size="s"
                        error={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, udeo: e.target.value })}
                        style={{ margin: "0 50px 0 0" }}
                    />
                ) : null}

                {formData.tip === VrstaNepokretnosti.OBJEKAT || formData.tip === VrstaNepokretnosti.POSEBAN_DEO ? (
                    <KatakomInput
                        type="text"
                        value={formData.brojObjekta || ""}
                        name="brojObjekta"
                        label="Број објекта*"
                        size="s"
                        error={false}
                        onFocus={async () => {
                            await showSelect();
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, brojObjekta: e.target.value })}
                        style={{ margin: "0 0px 0 0" }}
                    />
                ) : null}
                {formData.tip === VrstaNepokretnosti.POSEBAN_DEO ? (
                    <KatakomInput
                        type="text"
                        value={formData.evidencijskiBroj || ""}
                        name="evidencijskiBroj"
                        label="Евиденцијски број посебног дела*"
                        onFocus={async () => {
                            await showSelectPosebniDeo();
                        }}
                        size="s"
                        error={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, evidencijskiBroj: e.target.value })}
                        style={{ margin: "0 0px 0 50px" }}
                    />
                ) : null}
            </Row>
            {formData.tip === VrstaNepokretnosti.POSEBAN_DEO ? (
                <Row>
                    <KatakomInput
                        type="text"
                        value={formData.udeo || ""}
                        name="udeo"
                        label="Удео"
                        onFocus={() => {
                            setShowSelectTable(false);
                            setShowSelectTablePosebniDeo(false);
                        }}
                        size="s"
                        error={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, udeo: e.target.value })}
                    />
                </Row>
            ) : null}
            {showSelectTable ? (
                <Row>
                    <SelectTable
                        tableData={tableData}
                        onRowClick={(item) => {
                            setFormData({ ...formData, brojObjekta: item.BrDelaParc });
                            setShowSelectTable(false);
                            setShowSelectTablePosebniDeo(false);
                        }}
                        type="objekat"
                    />
                </Row>
            ) : null}

            {showSelectTablePosebniDeo ? (
                <Row>
                    <SelectTable
                        tableData={tableData}
                        onRowClick={(item) => {
                            setFormData({ ...formData, brojObjekta: item.BrDelaParc, evidencijskiBroj: item.evidencijskiBroj });
                            setShowSelectTablePosebniDeo(false);
                        }}
                        type="deoObjekta"
                    />
                </Row>
            ) : null}

            <Row style={{ justifyContent: "flex-end", marginLeft: "-220px" }}>
                <KatakomButton
                    label={"Сачувај"}
                    disabled={disabled || validatingParcelaDisabled}
                    tooltipMessage={validatingParcelaDisabled ? "Проверава се валидност парцеле..." : ""}
                    onClick={async () => {
                        await handleSave();
                    }}
                />
                <KatakomButton label={"Одустани"} onClick={handleClose} theme="secondary" style={{ marginLeft: "30px" }} />
            </Row>
        </ModalContent>
    );
};

export default AssetModalComponentAdvokati;
