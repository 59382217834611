import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { themeColors, themeIcon } from "../../constants/constants";
import { CaseAdvokatiState, VrstaLica } from "../../store/CaseAdvokatiReducer";
import { mandatoryDocuments, vrstaZahtevaAdvokatiOptions } from "../../register/register";
import { VrstaNepokretnosti } from "../../enums";
import moment from "moment";
import { getPrilogName } from "../../utils/checkStepValidation";

const styles = StyleSheet.create({
    document: {
        flexDirection: "column",
        backgroundColor: themeColors.primary,
        fontFamily: "Montserrat",
        fontWeight: 400,
        lineHeight: 1.5,
    },
    header: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    headerRepublikaSrbija: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 20,
    },

    grbSrbije: {
        width: 120,
        marginRight: "auto",
        marginLeft: 70,
    },

    profesionalniKorisnik: {
        position: "absolute",
        top: "60px",
        right: "100px",
        fontSize: "11px",
        whiteSpace: "nowrap",
    },

    headerSignature: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "60%",
        marginLeft: "40%",
    },
    headerPotpisMinistra: {
        marginTop: 30,
    },
    headerText: {
        fontSize: 12,
        textAlign: "center",
        marginTop: 0,
        fontWeight: 500,
    },
    header2Text: {
        ontSize: 12,
        textAlign: "center",
        marginTop: 20,
        marginBottom: 20,
        fontWeight: 500,
    },
    page: {
        flexDirection: "column",
        fontSize: 12,
        backgroundColor: themeColors.primary,
        padding: 40,
    },
    body: {
        display: "flex",
        fontSize: 12,
        flexDirection: "column",
        alignItems: "center",
        marginTop: 30,
    },
    boldTitle: {
        fontWeight: 600,
        fontSize: 16,
        marginTop: 50,
    },
    boldText: {
        fontWeight: 500,
        fontSize: 12,
        marginBottom: 20,
        textAlign: "center",
    },
    boldJustifyText: {
        fontWeight: 500,
        fontSize: 12,
        marginBottom: 20,
        textAlign: "justify",
    },
    boldData: {
        fontWeight: 500,
        fontSize: 12,
    },
    centerText: {
        textAlign: "center",
        fontWeight: 400,
    },
    justifyText: {
        textAlign: "justify",
        fontWeight: 400,
    },
    bodySection: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        textAlign: "left",
        alignItems: "flex-start",
        width: "100%",
    },
    leftText: {
        textAlign: "left",
        fontWeight: 400,
        minWidth: "430px",
        maxWidth: "430px",
    },
    boldLeftText: {
        fontWeight: 500,
        fontSize: 12,
        marginBottom: 20,
        textAlign: "left",
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    atributeName: {
        textAlign: "left",
        fontWeight: 400,
        minWidth: "140px",
        maxWidth: "140px",
    },
    section: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "12px",
    },
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Regular.ttf`,
    fontWeight: 400,
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Medium.ttf`,
    fontWeight: 500,
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-SemiBold.ttf`,
    fontWeight: 600,
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Bold.ttf`,
    fontWeight: 700,
});

interface GeneratePDFAdvokatiProps {
    pdfData: CaseAdvokatiState;
}

const GeneratePDFAdvokati: React.FC<GeneratePDFAdvokatiProps> = ({ pdfData }) => {
    const korisnik = localStorage.getItem("email");
    //@ts-ignore
    const grupaIsprave = mandatoryDocuments[String(pdfData?.basicInfo?.vrstaIspraveId)]?.groupType;
    //@ts-ignore
    let prilozi = mandatoryDocuments[pdfData.basicInfo.vrstaIspraveId].docs;
    prilozi = prilozi.map((prilog: string) => getPrilogName(prilog));

    return (
        <Document style={styles.document}>
            <Page style={styles.page}>
                <View style={styles.headerRepublikaSrbija}>
                    <View style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Text style={styles.headerText}>
                            Република Србија
                            {"\n"}
                            РЕПУБЛИЧКИ ГЕОДЕТСКИ ЗАВОД{"\n"}
                            СЛУЖБА ЗА КАТАСТАР НЕПОКРЕТНОСТИ_{pdfData.nepokretnostKN[0].koNaziv}
                            {"\n"}
                        </Text>
                    </View>
                </View>

                <View style={styles.body}>
                    <Text style={styles.justifyText}>
                        У складу са чланом 25. став 1. Закона о поступку уписа у катастар непокретности и водова („Службени гласник РС”, бр.
                        72/09, 18/10, 65/13, 15/15 – УС и 96/15) као професионални корисник у име подносиоца захтева достављам :
                        {/* <Text style={styles.boldData}>{pdfData.basicInfo.informacijeOLokacijiBroj}</Text> */}
                        {"\n"}
                    </Text>
                    <Text style={styles.boldTitle}>ЗАХТЕВ</Text>
                    <Text style={styles.boldText}>за провођење промене у катастру непокретности</Text>

                    <View style={styles.bodySection}>
                        <View style={styles.row}>
                            <Text style={styles.boldLeftText}>1. Врста захтева: </Text>
                            <Text style={styles.leftText}>
                                {vrstaZahtevaAdvokatiOptions.find((op) => op.value == pdfData.basicInfo.vrstaIspraveId)?.label}
                            </Text>
                        </View>
                    </View>

                    <View style={styles.bodySection}>
                        <Text style={styles.boldLeftText}>2. ПОДАЦИ О ПОДНОСИОЦУ ЗАХТЕВА</Text>
                        {pdfData.ucesnici.map((ucesnik, index) => (
                            <View key={index} style={styles.bodySection}>
                                {ucesnik.vrstaLica === VrstaLica.FIZICKO_DOMACE ? (
                                    pdfData.basicInfo.vrstaIspraveId === "6105" ? (
                                        <View style={styles.section}>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Врста лица:</Text>
                                                <Text>{ucesnik.vrstaLica}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>ЈМБГ:</Text>
                                                <Text>{ucesnik.jmbg}</Text>
                                            </View>
                                        </View>
                                    ) : (
                                        <View style={styles.section}>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Врста лица:</Text>
                                                <Text>{ucesnik.vrstaLica}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Име:</Text>
                                                <Text>{ucesnik.ime}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Презиме:</Text>
                                                <Text>{ucesnik.prezime}</Text>
                                            </View>
                                            {ucesnik.imeRoditelja ? (
                                                <View style={styles.row}>
                                                    <Text style={styles.atributeName}>Име родитеља:</Text>
                                                    <Text>{ucesnik.imeRoditelja}</Text>
                                                </View>
                                            ) : null}
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>ЈМБГ:</Text>
                                                <Text>{ucesnik.jmbg}</Text>
                                            </View>
                                            {ucesnik.brojLicneKarte ? (
                                                <View style={styles.row}>
                                                    <Text style={styles.atributeName}>Број личне карте:</Text>
                                                    <Text>{ucesnik.brojLicneKarte}</Text>
                                                </View>
                                            ) : null}
                                            {ucesnik.email ? (
                                                <View style={styles.row}>
                                                    <Text style={styles.atributeName}>И-пошта:</Text>
                                                    <Text>{ucesnik.email}</Text>
                                                </View>
                                            ) : null}
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Општина:</Text>
                                                <Text>{ucesnik.opstinaNaziv}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Место:</Text>
                                                <Text>{ucesnik.mestoNaziv}</Text>
                                            </View>
                                            {ucesnik.ulicaRucno ? (
                                                <View style={styles.row}>
                                                    <Text style={styles.atributeName}>Улица:</Text>
                                                    <Text>{ucesnik.ulicaRucno}</Text>
                                                </View>
                                            ) : null}
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Кућни број:</Text>
                                                <Text>{ucesnik.kucniBroj}</Text>
                                            </View>
                                            {ucesnik.postanskiBroj ? (
                                                <View style={styles.row}>
                                                    <Text style={styles.atributeName}>Поштански број:</Text>
                                                    <Text>{ucesnik.postanskiBroj}</Text>
                                                </View>
                                            ) : null}
                                        </View>
                                    )
                                ) : null}
                                {ucesnik.vrstaLica === VrstaLica.FIZICKO_STRANAC ? (
                                    pdfData.basicInfo.vrstaIspraveId === "6105" ? (
                                        <View style={styles.section}>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Врста лица:</Text>
                                                <Text>{ucesnik.vrstaLica}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Број пасоша:</Text>
                                                <Text>{ucesnik.brojPasosa}</Text>
                                            </View>
                                        </View>
                                    ) : (
                                        <View style={styles.section}>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Врста лица:</Text>
                                                <Text>{ucesnik.vrstaLica}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Име:</Text>
                                                <Text>{ucesnik.ime}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Презиме:</Text>
                                                <Text>{ucesnik.prezime}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Име родитеља:</Text>
                                                <Text>{ucesnik.imeRoditelja}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Број пасоша:</Text>
                                                <Text>{ucesnik.brojPasosa}</Text>
                                            </View>
                                            {ucesnik.ssn ? (
                                                <View style={styles.row}>
                                                    <Text style={styles.atributeName}>SSN(Social Security Number):</Text>
                                                    <Text>{ucesnik.ssn}</Text>
                                                </View>
                                            ) : null}
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Држава:</Text>
                                                <Text>{ucesnik.drzavaNaziv}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Место:</Text>
                                                <Text>{ucesnik.mestoNaziv}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Улица:</Text>
                                                <Text>{ucesnik.ulicaRucno}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Кућни број:</Text>
                                                <Text>{ucesnik.kucniBroj}</Text>
                                            </View>
                                        </View>
                                    )
                                ) : null}
                                {ucesnik.vrstaLica === VrstaLica.PRAVNO_DOMACE ? (
                                    pdfData.basicInfo.vrstaIspraveId === "6105" ? (
                                        <View style={styles.section}>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Врста лица:</Text>
                                                <Text>{ucesnik.vrstaLica}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Матични број:</Text>
                                                <Text>{ucesnik.maticniBroj}</Text>
                                            </View>
                                        </View>
                                    ) : (
                                        <View style={styles.section}>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Врста лица:</Text>
                                                <Text>{ucesnik.vrstaLica}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Име правног лица:</Text>
                                                <Text>{ucesnik.imePravnogLica}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Матични број:</Text>
                                                <Text>{ucesnik.maticniBroj}</Text>
                                            </View>
                                            {ucesnik.pib ? (
                                                <View style={styles.row}>
                                                    <Text style={styles.atributeName}>ПИБ:</Text>
                                                    <Text>{ucesnik.pib}</Text>
                                                </View>
                                            ) : null}
                                            {ucesnik.email ? (
                                                <View style={styles.row}>
                                                    <Text style={styles.atributeName}>И-пошта:</Text>
                                                    <Text>{ucesnik.email}</Text>
                                                </View>
                                            ) : null}
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Општина:</Text>
                                                <Text>{ucesnik.opstinaNaziv}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Место:</Text>
                                                <Text>{ucesnik.mestoNaziv}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Улица:</Text>
                                                <Text>{ucesnik.ulicaRucno}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Кућни број:</Text>
                                                <Text>{ucesnik.kucniBroj}</Text>
                                            </View>
                                            {ucesnik.postanskiBroj ? (
                                                <View style={styles.row}>
                                                    <Text style={styles.atributeName}>Поштански број:</Text>
                                                    <Text>{ucesnik.postanskiBroj}</Text>
                                                </View>
                                            ) : null}
                                        </View>
                                    )
                                ) : null}
                                {ucesnik.vrstaLica === VrstaLica.PRAVNO_STRANAC ? (
                                    <View style={styles.section}>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Врста лица:</Text>
                                            <Text>{ucesnik.vrstaLica}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Име правног лица:</Text>
                                            <Text>{ucesnik.imePravnogLica}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Број уписа у регистар државе седишта:</Text>
                                            <Text>{ucesnik.brojUpisaURegistarDrzaveSedista}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Назив регистра:</Text>
                                            <Text>{ucesnik.nazivRegistra}</Text>
                                        </View>
                                        {ucesnik.email ? (
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>И-пошта:</Text>
                                                <Text>{ucesnik.email}</Text>
                                            </View>
                                        ) : null}
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Место:</Text>
                                            <Text>{ucesnik.mestoNaziv}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Улица:</Text>
                                            <Text>{ucesnik.ulicaRucno}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Кућни број:</Text>
                                            <Text>{ucesnik.kucniBroj}</Text>
                                        </View>
                                    </View>
                                ) : null}
                            </View>
                        ))}
                    </View>
                    <View style={styles.bodySection} wrap={pdfData.nepokretnostKN.length > 3 ? true : false}>
                        <Text style={styles.boldLeftText}>3. ПРЕДМЕТ ЗАХТЕВА</Text>
                        {pdfData.nepokretnostKN.map((nepokretnost, index) => (
                            <View key={index} style={styles.bodySection}>
                                {nepokretnost.tip === VrstaNepokretnosti.PARCELA ? (
                                    <View style={styles.section} wrap={false}>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Тип:</Text>
                                            <Text>{nepokretnost.tip}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Општина:</Text>
                                            <Text>{nepokretnost.opstinaNaziv}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Место:</Text>
                                            <Text>{nepokretnost.koNaziv}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Број парцеле:</Text>
                                            <Text>{nepokretnost.brojParcele}</Text>
                                        </View>
                                        {nepokretnost.podBrojParcele ? (
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Подброј парцеле:</Text>
                                                <Text>{nepokretnost.podBrojParcele}</Text>
                                            </View>
                                        ) : null}
                                        {nepokretnost.udeo ? (
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Удео:</Text>
                                                <Text>{nepokretnost.udeo}</Text>
                                            </View>
                                        ) : null}
                                    </View>
                                ) : null}
                                {nepokretnost.tip === VrstaNepokretnosti.OBJEKAT ? (
                                    <View style={styles.section} wrap={false}>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Тип:</Text>
                                            <Text>{nepokretnost.tip}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Општина:</Text>
                                            <Text>{nepokretnost.opstinaNaziv}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Место:</Text>
                                            <Text>{nepokretnost.koNaziv}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Број парцеле:</Text>
                                            <Text>{nepokretnost.brojParcele}</Text>
                                        </View>
                                        {nepokretnost.podBrojParcele ? (
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Подброј парцеле:</Text>
                                                <Text>{nepokretnost.podBrojParcele}</Text>
                                            </View>
                                        ) : null}
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Број објекта:</Text>
                                            <Text>{nepokretnost.brojObjekta}</Text>
                                        </View>
                                        {nepokretnost.udeo ? (
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Удео:</Text>
                                                <Text>{nepokretnost.udeo}</Text>
                                            </View>
                                        ) : null}
                                    </View>
                                ) : null}
                                {nepokretnost.tip === VrstaNepokretnosti.POSEBAN_DEO ? (
                                    <View style={styles.section} wrap={false}>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Тип:</Text>
                                            <Text>{nepokretnost.tip}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Општина:</Text>
                                            <Text>{nepokretnost.opstinaNaziv}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Место:</Text>
                                            <Text>{nepokretnost.koNaziv}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Број парцеле:</Text>
                                            <Text>{nepokretnost.brojParcele}</Text>
                                        </View>
                                        {nepokretnost.podBrojParcele ? (
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Подброј парцеле:</Text>
                                                <Text>{nepokretnost.podBrojParcele}</Text>
                                            </View>
                                        ) : null}
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Број објекта:</Text>
                                            <Text>{nepokretnost.brojObjekta}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Евиденцијски број посебног дела објекта:</Text>
                                            <Text>{nepokretnost.evidencijskiBroj}</Text>
                                        </View>
                                        {nepokretnost.udeo ? (
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Удео:</Text>
                                                <Text>{nepokretnost.udeo}</Text>
                                            </View>
                                        ) : null}
                                    </View>
                                ) : null}

                                {pdfData.basicInfo.vrstaIspraveId === "6105" ? (
                                    <View style={styles.section} wrap={false}>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Општина:</Text>
                                            <Text>{nepokretnost.opstinaNaziv}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Место:</Text>
                                            <Text>{nepokretnost.koNaziv}</Text>
                                        </View>
                                    </View>
                                ) : grupaIsprave === "3" || grupaIsprave === "4" ? (
                                    <View style={styles.section} wrap={false}>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Општина:</Text>
                                            <Text>{nepokretnost.opstinaNaziv}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Место:</Text>
                                            <Text>{nepokretnost.koNaziv}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.atributeName}>Број парцеле:</Text>
                                            <Text>{nepokretnost.brojParcele}</Text>
                                        </View>
                                        {nepokretnost.podBrojParcele ? (
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Подброј парцеле:</Text>
                                                <Text>{nepokretnost.podBrojParcele}</Text>
                                            </View>
                                        ) : null}
                                        {nepokretnost.udeo ? (
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Удео:</Text>
                                                <Text>{nepokretnost.udeo}</Text>
                                            </View>
                                        ) : null}
                                        {nepokretnost.brojVoda ? (
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Број вода:</Text>
                                                <Text>{nepokretnost.brojVoda}</Text>
                                            </View>
                                        ) : null}
                                        {nepokretnost.vlasnik ? (
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Власник:</Text>
                                                <Text>{nepokretnost.vlasnik}</Text>
                                            </View>
                                        ) : null}
                                        {nepokretnost.list ? (
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Лист:</Text>
                                                <Text>{nepokretnost.list}</Text>
                                            </View>
                                        ) : null}
                                        {nepokretnost.opisVoda ? (
                                            <View style={styles.row}>
                                                <Text style={styles.atributeName}>Опис вода:</Text>
                                                <Text>{nepokretnost.opisVoda}</Text>
                                            </View>
                                        ) : null}
                                    </View>
                                ) : null}
                            </View>
                        ))}
                    </View>

                    <View style={styles.bodySection} wrap={false}>
                        <View style={styles.row}>
                            <Text style={styles.boldLeftText}>4. Прилози: </Text>
                        </View>
                        {prilozi.map((prilog: string, index: number) => {
                            return (
                                <View style={styles.row}>
                                    <Text>
                                        {index + 1}. {prilog}
                                    </Text>
                                </View>
                            );
                        })}
                    </View>
                </View>

                <View wrap={false}>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            width: "91%",
                            marginTop: "50px",
                        }}
                    >
                        <Text>Пуномоћник подносилац захтева:</Text>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "90%",
                            justifyContent: "space-between",
                            margin: "80px 20px 0 20px",
                        }}
                    >
                        <Text>Датум: {moment().format("DD.MM.YYYY")}</Text>
                        <Text>__________________________</Text>
                    </View>
                    <View
                        style={{
                            fontSize: "8px",
                            display: "flex",
                            flexDirection: "row",
                            width: "83%",
                            justifyContent: "flex-end",
                            marginTop: "-2px",
                        }}
                    >
                        <Text>(потпис)</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default GeneratePDFAdvokati;
