import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
    MdFactCheck,
    MdLibraryAdd,
    MdOutlineCases,
    MdOutlineManageSearch,
    MdOutlinePassword,
    MdOutlineSettings,
    MdLocationCity,
} from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import { themeColors } from "../../../constants/constants";
import { RootState } from "../../../store";
import { SidebarItem } from "./SidebarData";
import { useSidebar } from "./SidebarContext";
import { ROLE } from "../../../enums/role";

// Interfaces

interface SubMenuProps {
    item: SidebarItem;
    customColor?: "primary" | "secondary";
    tabIndex?: number | undefined;
}

interface SidebarLinkProps {
    isOpened: boolean;
    isActive: boolean;
    isSubnav: boolean;
    customColor?: "primary" | "secondary";
}

interface DropdownLinkProps {
    isLastSubnav: boolean;
    isActive: boolean;
}

// Styled Components

const SidebarLink = styled(Link)<SidebarLinkProps>`
    display: flex;
    color: ${(props) =>
        props.isActive ? (props.customColor === "secondary" ? themeColors.secondary : themeColors.tertiary) : themeColors.primary};
    justify-content: space-between;
    align-items: center;
    list-style: none;
    height: 50px;
    text-decoration: none;
    font-size: 18px;
    padding: ${(props) => (props.isOpened && props.isSubnav ? `12px 12px 0 12px` : "12px")};
    border-bottom: ${(props) => (!props.isOpened || !props.isSubnav ? `1px solid ${themeColors.primary}` : "none")};
    font-weight: ${(props) => (props.isActive ? "700" : "none")};
    &:focus,
    &:hover {
        background: #434951;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 17px;
    font-size: 20px;
`;

const SubnavLabel = styled.span`
    margin-left: 4px;
    color: ${themeColors.primary};
`;

const DropdownLink = styled(Link)<DropdownLinkProps>`
    background: ${themeColors.secondary};
    height: 60px;
    padding-left: 2.5rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${themeColors.primary};
    font-size: 16px;
    border-bottom: ${(props) => (props.isLastSubnav ? `1px solid ${themeColors.primary}` : "none")};
    &:focus,
    &:hover {
        background: #434951;
    }
`;

const ArrowIcon = styled.div`
    font-size: 22px;
`;

const SubMenu: React.FC<SubMenuProps> = ({ item, customColor, tabIndex }) => {
    const { sidebar, showSidebar } = useSidebar();
    const [subnav, setSubnav] = useState<boolean>(false);
    const [lastSubnavIndex, setLastSubnavIndex] = useState<number>(0);
    const location = useLocation();
    const email = localStorage.getItem("email");
    const subMenuRef = useRef<HTMLAnchorElement>(null);
    const userRole = useSelector((state: RootState) => state.user.role);

    const showSubnav = () => setSubnav(!subnav);

    useEffect(() => {
        const lastIndex = item.subNav ? item.subNav.length - 1 : 0;
        setLastSubnavIndex(lastIndex);
    }, [item.subNav]);

    useEffect(() => {
        if (!sidebar) {
            setSubnav(false);
        }
    }, [sidebar]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
        if (["ArrowRight", "ArrowLeft"].includes(e.key)) {
            e.preventDefault();
            showSubnav();
        }
    };
    const handleClick = () => {
        if (item.path === "/geoSrbija") {
            window.open("https://a3.geosrbija.rs", "_blank");
        } else {
            showSubnav();
        }
    };

    const isActive: boolean = location.pathname.includes(item.path);
    const isSubnav: boolean = Boolean(item?.subNav);

    const renderIcon = (icon: string) => {
        const iconMap: { [key: string]: JSX.Element } = {
            zahtevi: <MdOutlineCases />,
            pretraga: <MdOutlineManageSearch />,
            status: <MdFactCheck />,
            password: <MdOutlinePassword />,
            kreiraj: <MdLibraryAdd />,
            podesavanja: <MdOutlineSettings />,
            geoSrbija: <MdLocationCity />,
        };
        return iconMap[icon] || null;
    };

    if (item.path === "/eStatus" && email && userRole == ROLE.MGSI) return null;
    if (
        (item.path === "/noviPredmet" || item.path === "/statusZahteva") &&
        email &&
        (userRole == ROLE.MOBILE ||
            userRole === ROLE.GEODET ||
            userRole === ROLE.IZVRSITELJ ||
            userRole === ROLE.NEKRETNINE ||
            userRole === ROLE.INFOCENTAR ||
            userRole === ROLE.OPSTINA ||
            userRole === ROLE.BANK ||
            userRole === ROLE.ADMIN_BANK)
    )
        return null;
    if (item.path === "/podesavanja/kreirajNalog" && email && userRole !== ROLE.ADMIN && userRole !== ROLE.SUPERADMIN) return null;

    return (
        <>
            <SidebarLink
                to={isSubnav ? "#" : item.path}
                ref={subMenuRef}
                onClick={() => {
                    handleClick();
                }}
                onFocus={() => {
                    if (!sidebar) showSidebar();
                }}
                onKeyDown={handleKeyDown}
                isOpened={subnav}
                isActive={isActive}
                isSubnav={isSubnav}
                customColor={customColor}
                tabIndex={tabIndex}
            >
                <div style={{ display: "flex", marginLeft: 8 }}>
                    <ArrowIcon>{renderIcon(item.icon)}</ArrowIcon>
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <ArrowIcon>{isSubnav && (subnav ? <RiIcons.RiArrowDownSLine /> : <RiIcons.RiArrowRightSLine />)}</ArrowIcon>
            </SidebarLink>
            {subnav &&
                item.subNav?.map((subItem, index) => {
                    const isLastSubnav = index === lastSubnavIndex;
                    if (
                        (subItem.path === "/noviPredmet" &&
                            email &&
                            userRole !== ROLE.ADMIN &&
                            userRole !== ROLE.SUPERADMIN &&
                            userRole !== ROLE.IZVRSITELJ) ||
                        (subItem.path === "/noviPredmetAdvokati" &&
                            email &&
                            userRole !== ROLE.ADMIN &&
                            userRole !== ROLE.SUPERADMIN &&
                            userRole !== ROLE.ADVOKAT) ||
                        (subItem.path === "/noviPredmetKonverzija" &&
                            email &&
                            userRole !== ROLE.ADMIN &&
                            userRole !== ROLE.SUPERADMIN &&
                            userRole !== ROLE.MGSI)
                    ) {
                        return null;
                    }
                    return (
                        <DropdownLink to={subItem.path} key={index} isLastSubnav={isLastSubnav} isActive={isActive} tabIndex={tabIndex}>
                            {subItem.icon}
                            {isSubnav ? <SubnavLabel>{subItem.title}</SubnavLabel> : <SidebarLabel>{subItem.title}</SidebarLabel>}
                        </DropdownLink>
                    );
                })}
        </>
    );
};

export default SubMenu;
