import React, { useEffect, useRef, useState } from "react";
import * as RiIcons from "react-icons/ri";
import styled from "styled-components";
import { themeColors } from "../../constants/constants";
import { translateToCyrilic, translateToLatinic } from "../../utils/translate";

import useMobileCheck from "../../hooks/useMobileChecker";

export interface SelectOption {
    value: string;
    label: string;
}

interface SelectProps {
    options: SelectOption[];
    value: string;
    name: string;
    label?: string;
    size?: "s" | "m" | "l" | "xl";
    error: boolean;
    disabled?: boolean;
    onChange: (option: SelectOption) => void;
    style?: React.CSSProperties;
    theme?: string;
    tabIndex?: number | undefined;
}

const SelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const SelectContainer = styled.div<{ size: string }>`
    position: relative;
    width: ${(props) => props.size};
`;

const SearchInput = styled.input`
    font-size: 16px;
    width: 80%;
    border: none;
    background: none;
    color: ${themeColors.secondary};
    outline: none;
    padding: 8px 12px;
`;

const SelectButton = styled.button`
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    color: ${themeColors.secondary};
    background: ${themeColors.primary};
    border: none;
    box-shadow: none;
    outline: none;
    border-radius: 4px;
    box-shadow: 5px 5px 10px 0px #00000040;
    cursor: pointer;
    display: flex;
    justify-content: left;
    align-items: center;
    height: 50px;
    overflow: hidden;
`;

const SelectButtonText = styled.span`
    padding: 0 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
    text-align: left;
`;

const SelectDropdown = styled.ul`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 300px;
    background-color: ${themeColors.primary};
    border-radius: 4px;
    padding: 0;
    margin: 0;
    box-shadow: 5px 5px 10px 0px #00000040;
    z-index: 10;
    text-decoration: none;
    list-style: none;
    overflow-y: scroll;
`;

const SelectOptionContainer = styled.li<{ highlighted: boolean }>`
    padding: 8px 12px;
    cursor: pointer;
    color: ${themeColors.secondary};
    background-color: ${(props) => (props.highlighted ? themeColors.tertiary : "transparent")};

    &:hover {
        background-color: ${themeColors.tertiary};
    }
`;

const Label = styled.label`
    font-size: 15px;
    font-weight: 400;
    color: ${themeColors.secondary};
    height: 40px;
`;

const Error = styled.p`
    color: ${themeColors.tertiary};
    font-size: 16px;
    font-weight: 400;
    margin-left: 12px;
    margin-top: 4px;
`;

const ArrowIcon = styled.span<{ isOpen: boolean }>`
    position: absolute;
    top: 28px;
    right: 4px;
    font-size: 22px;
    transform: translate(-50%, -50%) ${(props) => (props.isOpen ? "rotate(90deg)" : "rotate(0)")};
    transition: transform 0.2s ease-in-out;
    color: ${themeColors.tertiary};
`;

const ClearIcon = styled.span`
    position: absolute;
    top: 28px;
    right: 14px;
    transform: translateY(-50%);
    font-size: 18px;
    color: ${themeColors.tertiary};
    cursor: pointer;
`;

const KatakomSelect: React.FC<SelectProps> = ({ options, value, name, label, error, disabled, theme, onChange, size, style, tabIndex }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [hoveredIndex, setHoveredIndex] = useState(-1);
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const dropdownRef = useRef<HTMLUListElement | null>(null);
    const isMobile = useMobileCheck();

    const handleFocus = () => {
        setIsOpen(true);
    };

    //@ts-ignore
    const handleBlur = (event) => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                searchInputRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                !searchInputRef.current.contains(event.target as Node)
            ) {
                // setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && dropdownRef.current && hoveredIndex === -1) {
            const highlightedOption = dropdownRef.current.children[highlightedIndex] as HTMLElement;
            if (highlightedOption) {
                highlightedOption.scrollIntoView({ block: "center" });
            }
        }
    }, [highlightedIndex, isOpen]);

    const getSize = (size?: string) => {
        switch (size) {
            case "s":
                return "200px";
            case "m":
                return "270px";
            case "l":
                return "330px";
            case "xl":
                return "450px";
            default:
                return "270px";
        }
    };

    const handleClick = () => {
        setSearch("");
        setHighlightedIndex(-1);
    };

    const handleSelect = (option: SelectOption) => {
        onChange(option);
        setIsOpen(false);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        setHighlightedIndex(-1);
    };

    const handleMouseEnter = (index: number) => {
        setHoveredIndex(index);
        setHighlightedIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(-1);
    };

    const handleTouchStart = (index: number) => {
        setHoveredIndex(index);
        setHighlightedIndex(index);
    };

    const handleTouchEnd = (option: SelectOption, index: number) => {
        if (hoveredIndex === index) {
            onChange(option);
        }
        setHighlightedIndex(index);
    };

    const handleScroll = (event: React.TouchEvent<HTMLUListElement>) => {
        event.stopPropagation();
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        switch (event.key) {
            case "ArrowUp":
                event.preventDefault();
                if (hoveredIndex === -1) {
                    setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
                }
                break;
            case "ArrowDown":
                event.preventDefault();
                if (hoveredIndex === -1) {
                    setHighlightedIndex((prevIndex) => Math.min(prevIndex + 1, filteredOptions.length - 1));
                }
                break;
            case "Enter":
                if (highlightedIndex !== -1) {
                    handleSelect(filteredOptions[highlightedIndex]);
                }
                break;
            default:
                break;
        }
    };

    const handleOptionClick = (option: SelectOption, index: number) => {
        handleSelect(option);
        setHighlightedIndex(index);
    };

    const filteredOptions = options.filter((option) =>
        translateToLatinic(option.label.toLowerCase()).includes(translateToLatinic(translateToCyrilic(search.toLowerCase())))
    );

    const selectSize = getSize(size);

    const handleClear = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.stopPropagation();
        onChange({ value: "", label: "" });
    };

    return (
        <SelectWrapper style={{ ...style }}>
            <Label>{label}</Label>
            <SelectContainer
                size={selectSize}
                onFocus={
                    isMobile
                        ? () => {}
                        : () => {
                              handleFocus();
                          }
                }
                onClick={
                    isMobile
                        ? () => {
                              setIsOpen(!isOpen);
                          }
                        : () => {}
                }
                tabIndex={tabIndex}
            >
                <SelectButton onClick={handleClick} disabled={disabled} id={name}>
                    <SelectButtonText>{options.find((option) => (value ? option.value.includes(value) : null))?.label}</SelectButtonText>
                    {value ? (
                        <ClearIcon onClick={handleClear}>
                            <RiIcons.RiCloseCircleLine />
                        </ClearIcon>
                    ) : (
                        <ArrowIcon isOpen={isOpen}>{<RiIcons.RiArrowRightSLine />}</ArrowIcon>
                    )}
                </SelectButton>
                {isOpen && (
                    <SelectDropdown
                        ref={dropdownRef}
                        onBlur={
                            isMobile
                                ? () => {}
                                : (e) => {
                                      handleBlur(e);
                                  }
                        }
                        onTouchMove={handleScroll}
                    >
                        <SearchInput
                            ref={searchInputRef}
                            type="text"
                            placeholder="Претражи"
                            value={search}
                            onChange={handleSearchChange}
                            onKeyDown={handleKeyDown}
                        />
                        {filteredOptions.map((option, index) => (
                            <SelectOptionContainer
                                key={option.value}
                                onMouseDown={
                                    isMobile
                                        ? () => {}
                                        : (e) => {
                                              handleOptionClick(option, index);
                                          }
                                }
                                onMouseEnter={isMobile ? () => {} : () => handleMouseEnter(index)}
                                onMouseLeave={isMobile ? () => {} : handleMouseLeave}
                                onClick={
                                    isMobile
                                        ? (e) => {
                                              handleSelect(option);
                                          }
                                        : () => {}
                                }
                                onTouchStart={() => handleTouchStart(index)}
                                onTouchEnd={() => handleTouchEnd(option, index)}
                                highlighted={highlightedIndex === index || hoveredIndex === index}
                            >
                                {option.label}
                            </SelectOptionContainer>
                        ))}
                    </SelectDropdown>
                )}
            </SelectContainer>
            {error && <Error>Обавезно поље!</Error>}
        </SelectWrapper>
    );
};

export default KatakomSelect;
