import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { themeColors, themeIcon } from "../../constants/constants";
import api from "../../services/api";

const ChatbotContainer = styled.div`
    position: absolute;
    bottom: 90px; /* Position it above the button */
    right: 24px;
    width: 280px;
    height: 370px;
    background-color: ${themeColors.primary};
    border: none;
    outline: none;
    border-radius: 5px;
    overflow-y: auto;
    box-shadow: -5px 5px 10px 0px #00000040;
    display: flex;
    flex-direction: column;
`;

const ChatbotTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: ${themeColors.secondary};
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: ${themeColors.primary};
    font-size: 16px;
`;

const ChatbotBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 300px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    img {
        margin: 15px;
        width: 100px;
    }
`;

const Button = styled.button`
    outline: none;
    border: none;
    background-color: ${themeColors.quaternary};
    color: ${themeColors.secondary};
    font-size: 12px;
    padding: 15px 20px;
    margin: 15px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px 0px #00000040;
    cursor: pointer;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: flex-start;
    margin-top: 8px; /* Adjust margin top */
`;

const TitleField = styled.input`
    outline: none;
    border: 2px solid ${themeColors.quaternary};
    padding: 10px;
    height: 20px;
    width: 200px;
    margin: 8px;
    border-radius: 5px;
    font-size: 14px;
`;
const TextField = styled.textarea`
    font-size: 14px;
    outline: none;
    border: 2px solid ${themeColors.quaternary};
    padding: 10px;
    height: 80px;
    width: 200px;
    max-width: 200px;
    margin: 8px;
    border-radius: 5px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    min-height: 140px;
    max-height: 140px;
    overflow-y: auto;
    line-height: 1.5;
    word-wrap: break-word;
    cursor: text;

    &:empty:before {
        content: attr(placeholder);
        color: ${themeColors.tertiary};
        pointer-events: none;
    }
`;

const Label = styled.label`
    color: ${themeColors.secondary};
    font-size: 14px;
    margin: 5px;
`;

interface Props {
    turnOffChatbot: () => void;
}

export const Chatbot: React.FC<Props> = ({ turnOffChatbot }) => {
    const [beforeConversationStarts, setBeforeConversationStarts] = useState<boolean>(true);
    const [conversationStarted, setConversationStarted] = useState<boolean>(false);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [inputMessage, setInputMessage] = useState<string>("");

    const handleMessageSend = () => {
        api.post("api/v1/send-support-email", { message: inputMessage });
        setConversationStarted(false);
        setEmailSent(true);
    };

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputMessage(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            // Get cursor position
            const cursorPosition = e.currentTarget.selectionStart;
            // Insert newline character at cursor position
            setInputMessage((prevMessage) => {
                const newMessage = prevMessage.slice(0, cursorPosition) + "\n" + prevMessage.slice(cursorPosition);
                return newMessage;
            });
        }
    };

    const handleEscKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            turnOffChatbot();
        }
    };

    // Add event listener when component mounts
    useEffect(() => {
        document.addEventListener("keydown", handleEscKeyPress);

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener("keydown", handleEscKeyPress);
        };
    }, []);

    useEffect(() => {
        if (emailSent) {
            setTimeout(() => {
                turnOffChatbot();
            }, 5000);
        }
    }, [emailSent]);

    return (
        <ChatbotContainer>
            {beforeConversationStarts ? (
                <React.Fragment>
                    <ChatbotTitle>Започните разговор</ChatbotTitle>
                    <ChatbotBody>
                        <img src={`${themeIcon}/letter.png`} alt="Start conversation" />
                        <Button
                            onClick={() => {
                                setBeforeConversationStarts(false);
                                setConversationStarted(true);
                            }}
                        >
                            Започни разговор
                        </Button>
                        <p style={{ fontSize: "10px", color: themeColors.tertiary, margin: "15px" }}>
                            Просечно време одговора је сат времена
                        </p>
                    </ChatbotBody>
                </React.Fragment>
            ) : null}

            {conversationStarted ? (
                <React.Fragment>
                    <ChatbotTitle>Како можемо да помогнемо?</ChatbotTitle>
                    <ChatbotBody>
                        <InputWrapper>
                            <Label>Порука</Label>
                            <TextField value={inputMessage} onChange={handleTextAreaChange} onKeyDown={handleKeyDown} />
                        </InputWrapper>
                        <Button onClick={handleMessageSend}>Пошаљи</Button>
                    </ChatbotBody>
                </React.Fragment>
            ) : null}

            {emailSent ? (
                <React.Fragment>
                    <ChatbotTitle>Порука послата</ChatbotTitle>
                    <ChatbotBody>
                        <img src={`${themeIcon}/check.png`} alt="Start conversation" />

                        <p style={{ fontSize: "12px", color: themeColors.tertiary, margin: "15px", textAlign: "center" }}>
                            Порука је послата, одговорићемо вам у најкраћем могућем року!
                        </p>
                    </ChatbotBody>
                </React.Fragment>
            ) : null}
        </ChatbotContainer>
    );
};
