import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { themeColors } from "../../constants/constants";
import { translateToCyrilic, translateToLatinic } from "../../utils/translate";
import { groupTitles, vrstaZahtevaAdvokatiOptions } from "../../register/register";
import { useMediaQuery } from "usehooks-ts";

interface SelectOption {
    value: string;
    label: string;
}

interface SelectProps {
    options: SelectOption[];
    value: string;
    name: string;
    label?: string;
    size?: "s" | "m" | "l" | "xl";
    error: boolean;
    disabled?: boolean;
    onChange: (option: SelectOption) => void;
    style?: React.CSSProperties;
    theme?: string;
}

const SelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 97%;
    @media screen and (max-width: 900px) {
        width: 88%;
    }
`;

const SelectContainer = styled.div`
    position: relative;
    width: 100%;
`;

const SearchInput = styled.input`
    width: calc(100% - 16px);
    font-size: 16px;
    font-weight: 400;
    color: ${themeColors.secondary};
    background: ${themeColors.quinary};
    padding-left: 12px;
    border: none;
    outline: none;
    border-radius: 4px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    cursor: text;
    display: flex;
    justify-content: left;
    align-items: center;
    height: 50px;
    overflow: hidden;
    margin-bottom: 10px;
    @media screen and (max-width: 900px) {
        width: calc(100% - 20px);
    }
`;

const SelectDropdown = styled.ul<{ determineHeight: string }>`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: ${(props) => props.determineHeight};
    min-height: ${(props) => props.determineHeight};
    background-color: ${themeColors.quinary};
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    padding: 0;
    margin: 0;
    box-shadow: 5px 5px 10px 0px #00000040;
    z-index: 10;
    text-decoration: none;
    list-style: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: ${themeColors.quinary};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${themeColors.tertiary};
        border-radius: 3px;
        border: 1px solid ${themeColors.quinary};
    }
`;

const SelectOptionContainer = styled.li<{ highlighted: boolean }>`
    padding: 8px 16px;
    cursor: pointer;
    color: ${themeColors.secondary};
    font-weight: ${(props) => (props.highlighted ? "700" : "500")};
`;

const Label = styled.label`
    font-size: 15px;
    font-weight: 400;
    color: ${themeColors.secondary};
    height: 40px;
`;

const GroupDivider = styled.div`
    font-size: 16px;
    font-weight: 600;
    padding: 5px 10px;
    color: ${themeColors.secondary};
    box-shadow: 0px 4px 4px 0px #00000040;
    outline: none;
    border: none;
    margin: 15px 0 8px 0;
    cursor: default;
`;

const AdvokatiCustomSelect: React.FC<SelectProps> = ({ options, value, name, label, error, disabled, theme, onChange, size, style }) => {
    const [search, setSearch] = useState("");
    const [highlightedIndex, setHighlightedIndex] = useState(vrstaZahtevaAdvokatiOptions.findIndex((vrsta) => vrsta.value === value));
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const dropdownRef = useRef<HTMLUListElement | null>(null);
    const isMobile = useMediaQuery("(max-width: 1800px)");

    const determineHeight = isMobile ? "31vh" : "50vh";

    const handleSelect = (option: SelectOption) => {
        onChange(option);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        switch (event.key) {
            case "ArrowUp":
                event.preventDefault();
                setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
                handleSelect(highlightedIndex === 0 ? filteredOptions[filteredOptions.length - 1] : filteredOptions[highlightedIndex - 1]);
                break;
            case "ArrowDown":
                event.preventDefault();
                setHighlightedIndex((prevIndex) => Math.min(prevIndex + 1, filteredOptions.length - 1));
                handleSelect(highlightedIndex === filteredOptions.length - 1 ? filteredOptions[0] : filteredOptions[highlightedIndex + 1]);
                break;
            case "Enter":
                event.preventDefault();
                if (highlightedIndex !== -1) {
                    handleSelect(filteredOptions[highlightedIndex]);
                }
                break;
            default:
                break;
        }
    };

    const handleOptionClick = (option: SelectOption, index: number) => {
        handleSelect(option);
        setHighlightedIndex(index);
    };

    const filteredOptions = options.filter((option) =>
        translateToLatinic(option.label.toLowerCase()).includes(translateToLatinic(translateToCyrilic(search.toLowerCase())))
    );

    useEffect(() => {
        setHighlightedIndex(filteredOptions.findIndex((op) => op.value === value));
    }, [filteredOptions]);

    useEffect(() => {
        if (searchInputRef.current && !isMobile) {
            searchInputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (dropdownRef.current && highlightedIndex !== -1) {
            const highlightedElement = dropdownRef.current.querySelector(`[data-index="${highlightedIndex}"]`);
            if (highlightedElement) {
                const groupTitleElement = highlightedElement.previousElementSibling as HTMLElement;
                if (groupTitleElement && groupTitleElement.tagName === "DIV") {
                    groupTitleElement.scrollIntoView({ block: "start", behavior: "smooth" });
                } else {
                    highlightedElement.scrollIntoView({ block: "nearest", behavior: "smooth" });
                }
            }

            if (!isMobile) {
                searchInputRef.current?.focus();
            }
        }
    }, [highlightedIndex]);

    return (
        <SelectWrapper style={{ ...style }}>
            <Label>{label}</Label>
            <SelectContainer>
                <SearchInput
                    ref={searchInputRef}
                    type="text"
                    placeholder="Претражи"
                    value={search}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                />
                <SelectDropdown ref={dropdownRef} determineHeight={determineHeight}>
                    <GroupDivider style={{ marginTop: "0px" }}>{groupTitles["1"]}</GroupDivider>
                    {filteredOptions.map((option, index) => {
                        //@ts-ignore
                        if (option.groupType === "1") {
                            return (
                                <SelectOptionContainer
                                    key={option.value}
                                    onClick={() => handleOptionClick(option, index)}
                                    highlighted={highlightedIndex === index}
                                    data-index={index}
                                >
                                    {option.label}
                                </SelectOptionContainer>
                            );
                        } else {
                            return null;
                        }
                    })}

                    <GroupDivider>{groupTitles["2"]}</GroupDivider>
                    {filteredOptions.map((option, index) => {
                        //@ts-ignore
                        if (option.groupType === "2") {
                            return (
                                <SelectOptionContainer
                                    key={option.value}
                                    onClick={() => handleOptionClick(option, index)}
                                    highlighted={highlightedIndex === index}
                                    data-index={index}
                                >
                                    {option.label}
                                </SelectOptionContainer>
                            );
                        } else {
                            return null;
                        }
                    })}
                    <GroupDivider>{groupTitles["3"]}</GroupDivider>
                    {filteredOptions.map((option, index) => {
                        //@ts-ignore
                        if (option.groupType === "3") {
                            return (
                                <SelectOptionContainer
                                    key={option.value}
                                    onClick={() => handleOptionClick(option, index)}
                                    highlighted={highlightedIndex === index}
                                    data-index={index}
                                >
                                    {option.label}
                                </SelectOptionContainer>
                            );
                        } else {
                            return null;
                        }
                    })}
                    <GroupDivider>{groupTitles["4"]}</GroupDivider>
                    {filteredOptions.map((option, index) => {
                        //@ts-ignore
                        if (option.groupType === "4") {
                            return (
                                <SelectOptionContainer
                                    key={option.value}
                                    onClick={() => handleOptionClick(option, index)}
                                    highlighted={highlightedIndex === index}
                                    data-index={index}
                                >
                                    {option.label}
                                </SelectOptionContainer>
                            );
                        } else {
                            return null;
                        }
                    })}
                </SelectDropdown>
            </SelectContainer>
        </SelectWrapper>
    );
};

export default AdvokatiCustomSelect;
