import { FC, useState } from "react";
import KatakomButton from "../../shared/KatakomButton";
import KatakomInput from "../../shared/KatakomInput";
import { Row } from "../style";
import { SuccessMessageModal } from "../../customComponent/SuccessMessageModal";
import KatakomModal from "../../shared/KatakomModal";
import { isEmailValid } from "../../../utils/utils";
import { INVALID_USER_NAME_OR_PASSWORD } from "../../../constants/Error.constants";
import api from "../../../services/api";

interface Props {
    closeModal: () => void;
}

export const ResetPasswordModal: FC<Props> = ({ closeModal }) => {
    const [email, setEmail] = useState<string>("");
    const [kod, setKod] = useState<string>("");
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const handleResetPassword = async () => {
        if (!isEmailValid(email)) {
            setErrorMessage("Е-пошта није у исправном формату.");
            return;
        }

        try {
            const response = await api.post(`restart-password`, {
                email: email,
                code: kod,
            });
            if (response?.status === 200 || response?.status === 201) {
                setSuccessModal(true);
            }
        } catch (err: any) {
            setKod("");
            if (err.response.data.code === INVALID_USER_NAME_OR_PASSWORD.code) {
                setErrorMessage("Грешка приликом уноса података!");
            } else {
                setErrorMessage("Грешка приликом уноса података!");
            }
        }
    };

    return (
        <>
            <KatakomModal isOpen={successModal} setIsOpen={setSuccessModal}>
                <SuccessMessageModal
                    closeModal={() => {
                        setSuccessModal(false);
                        closeModal();
                    }}
                    message={"Нова лозинка је послата на вашу е-пошту!"}
                />
            </KatakomModal>

            <div style={{ margin: 20, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Row>
                    <KatakomInput
                        type={"text"}
                        label="Е-пошта"
                        value={email}
                        name={"Е-пошта"}
                        error={false}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                    />
                </Row>
                <Row>
                    <KatakomInput
                        type={"text"}
                        label="Код"
                        value={kod}
                        name={"Код"}
                        error={false}
                        onChange={(e) => {
                            setKod(e.target.value);
                        }}
                    />
                </Row>

                {errorMessage ? <div style={{ width: 280, marginTop: 20, textAlign: "center", color: "red" }}>{errorMessage}</div> : null}

                <KatakomButton
                    label={"Пошаљи"}
                    onClick={() => {
                        handleResetPassword();
                    }}
                    style={{ margin: 20, background: "#393e46" }}
                />

                <KatakomButton
                    label={"Одустани"}
                    theme="secondary"
                    onClick={() => {
                        closeModal();
                    }}
                />

                <div style={{ width: 280, marginTop: 20, textAlign: "center" }}>
                    Ако сте изгубили код јавите се на имејл: info.katakom@gmail.com
                </div>
            </div>
        </>
    );
};
