import styled from "styled-components";
import { themeColors } from "../../../constants/constants";

export const NoDataTable = () => {
    return (
        <NoDataTableContainer>
            <LogoContainer>
                <img src={`${process.env.PUBLIC_URL}/NoTableData.png`} alt="No data found" />
            </LogoContainer>{" "}
            <Text>Не постоји објекат са задатим вредностима</Text>
        </NoDataTableContainer>
    );
};

const NoDataTableContainer = styled.div`
    display: flex;
    width: calc(100%-130px);
    border: 2px solid ${themeColors.quaternary};
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
`;

const Text = styled.div`
    font-size: 14px;
    color: ${themeColors.quaternary};
`;

const LogoContainer = styled.div`
    img {
        width: 130px;
    }
`;
