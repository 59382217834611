import { FC } from "react";
import KatakomButton from "../shared/KatakomButton";
import styled from "styled-components";
import { themeColors, themeIcon } from "../../constants/constants";
import { Row } from "../pages/style";

interface Props {
    closeModal: () => void;
    message: string;
}

export const ErrorMessageModal: FC<Props> = ({ message, closeModal }) => {
    return (
        <ModalContainer>
            <ModalIcon />
            <ModalTitle>{message}</ModalTitle>
            <Row style={{ justifyContent: "center" }}>
                <KatakomButton
                    label={"Изађи"}
                    onClick={() => {
                        closeModal();
                    }}
                    style={{ margin: "0 30px 0 0 ", textAlign: "center" }}
                />
            </Row>
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
    width: 500px;
    height: 400px;
    background-color: ${themeColors.primary};
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    position: fixed;
    top: 20%;
    left: calc(50% - 250px);
    padding: 30px;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 300px;
        height: 300px;
        background-image: url(${themeIcon}/crossMarkBackground.png);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: contain;
        opacity: 1;
    }
`;

const ModalIcon = styled.div`
    width: 100px;
    height: 100px;
    margin: 30px auto;
    background-image: url(${themeIcon}/cross.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 1;
`;

const ModalTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    z-index: 1;
`;
