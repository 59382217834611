export const getThemeColors = (theme: string) => {
    switch (theme) {
        case "theme-default":
            return {
                primary: "#F7F7F7",
                secondary: "#393E46",
                tertiary: "#929AAB",
                quaternary: "#D4D4CE",
                quinary: "#EEEEEE",
                quaternaryFaded: "rgba(212, 212, 206, 0.5)",
            };
        case "theme-blue":
            return {
                primary: "#F6F6F6", // bela
                secondary: "#023246", //tamno plava
                tertiary: "#287094", //plava
                quaternary: "#D4D4CE", //siva
                quinary: "#EEEEEE",
                quaternaryFaded: "rgba(212, 212, 206, 0.5)",
            };
        case "theme-green":
            return {
                primary: "#FFFFFF", //Bela
                secondary: "#343a40", // tamno svia
                tertiary: "#009688", // zelena
                quaternary: "#ECECEC",
                quinary: "#ECECEC",
                quaternaryFaded: "rgba(212, 212, 206, 0.5)",
            };
        default:
            return {
                primary: "#F7F7F7",
                secondary: "#393E46",
                tertiary: "#3c3c3c",
                quaternary: "#4d4d4d",
                quinary: "#EEEEEE",
                quaternaryFaded: "rgba(212, 212, 206, 0.5)",
            };
    }
};
