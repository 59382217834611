import { ChangeEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import { themeColors } from "../../../../../constants/constants";
import { Container } from "../../../style";
import { addTempDocumentAdvokati, deleteTempDocumentAdvokati } from "../../../../../store/CaseAdvokatiReducer";
import { themeIcon } from "../../../../../constants/constants";
import api from "../../../../../services/api";
import { useMediaQuery } from "usehooks-ts";
import { mandatoryDocuments } from "../../../../../register/register";

const Label = styled.label`
    display: flex;
    flex-direction: column;
    width: 160px;
    margin: 10px;
    margin-right: 20px;
    text-align: center;
    color: ${themeColors.tertiary};

    input[type="file"] {
        display: none;
    }
`;

const Input = styled.input``;

const Image = styled.img<{ uploadedFile: boolean }>`
    width: ${(props) => (props.uploadedFile ? "100px" : "160px")};
    margin-top: ${(props) => (props.uploadedFile ? "19px" : "0px")};
    margin-bottom: ${(props) => (props.uploadedFile ? "19px" : "0px")};
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    &:focus,
    &:hover {
        background-color: ${(props) => (props.uploadedFile ? "none" : ` ${themeColors.quinary}`)};
        border-radius: ${(props) => (props.uploadedFile ? "none" : `16px`)};
        opacity: ${(props) => (props.uploadedFile ? "1" : `0.7`)};
    }
`;

const DeleteImage = styled.img`
    background-color: ${themeColors.tertiary};
    margin-top: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const FileName = styled.div`
    font-size: 16px;
    font-weight: 500;
    margin-top: 4px;
    width: 160px;
    word-wrap: break-word;
`;

const AddDocumentsAdvokati = () => {
    const isMobile = useMediaQuery("(max-width: 1400px)");
    const tempDokumenta = useSelector((state: RootState) => state.caseAdvokati.tempDokumenta);
    const viId = useSelector((state: RootState) => state.caseAdvokati.basicInfo.vrstaIspraveId);
    const [draggingOverDocument, setDraggingOverDocument] = useState<string | null>(null);

    const [documentStatus, setDocumentStatus] = useState({
        "27": false,
        "401": false,
        "405": false,
        "404": false,
        "601": false,
    });

    const dispatch: AppDispatch = useAppDispatch();

    const handleDeleteDocument = (vrstaIspraveId: string, name: string) => {
        dispatch(deleteTempDocumentAdvokati({ vrstaIspraveId, name }));
        setDocumentStatus({ ...documentStatus, [vrstaIspraveId]: false });
    };

    const handleFileUpload = async (files: FileList | null, vrstaIspraveId: string) => {
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.type === "application/pdf") {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = async () => {
                        const base64String = reader.result?.toString().split(",")[1];
                        if (base64String) {
                            try {
                                const isSigned = await hasDigitalSignature(base64String);
                                dispatch(
                                    addTempDocumentAdvokati({
                                        name: file.name,
                                        file: base64String,
                                        isSigned: Boolean(isSigned),
                                        category: "Kategorija 1",
                                        vrstaIspraveId: vrstaIspraveId,
                                    })
                                );
                                toggleDocumentStatus(vrstaIspraveId);
                            } catch (error) {
                                console.error(error);
                            }
                        } else {
                            console.error("Error while reading file");
                        }
                    };
                    reader.onerror = () => {
                        console.error("Error while reading file");
                    };
                } else {
                    console.error("Please select a PDF file");
                }
            }
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLLabelElement>, vrstaIspraveId: string) => {
        event.preventDefault();
        setDraggingOverDocument(vrstaIspraveId);
    };

    const handleDrop = (event: React.DragEvent<HTMLLabelElement>, vrstaIspraveId: string) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        handleFileUpload(droppedFiles, vrstaIspraveId);
        setDraggingOverDocument("");
    };

    const hasDigitalSignature = async (base64EncodedFile: string) => {
        const response = await api.post(`api/v1/has-digital-signature`, {
            base64Document: base64EncodedFile,
        });

        return response?.data;
    };

    const toggleDocumentStatus = (vrstaIspraveId: string) => {
        //@ts-ignore
        setDocumentStatus({ ...documentStatus, [vrstaIspraveId]: !documentStatus[vrstaIspraveId] });
    };
    const isDocumentUploaded = (vrstaIspraveId: string): boolean => {
        return tempDokumenta.some((doc) => doc.vrstaIspraveId === vrstaIspraveId);
    };

    const getUploadedDocuments = (vrstaIspraveId: string) => {
        return tempDokumenta.filter((doc) => doc.vrstaIspraveId === vrstaIspraveId);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLImageElement>, vrstaIspraveId: string) => {
        if (event.key === "Enter") {
            const fileInput = document.getElementById(`fileInput-${vrstaIspraveId}`) as HTMLInputElement | null;
            if (fileInput) {
                fileInput.click();
            }
        }

        const keys = ["27", "401", "405", "404", "601"];
        const currentIndex = keys.indexOf(vrstaIspraveId);

        if (event.key === "ArrowLeft") {
            const newIndex = (currentIndex - 1 + keys.length) % keys.length;
            //@ts-ignore
            imageRefs[keys[newIndex]].current?.focus();
        } else if (event.key === "ArrowRight") {
            const newIndex = (currentIndex + 1) % keys.length;
            //@ts-ignore
            imageRefs[keys[newIndex]].current?.focus();
        }
    };

    const imageRefs = {
        "27": useRef<HTMLImageElement>(null),
        "401": useRef<HTMLImageElement>(null),
        "405": useRef<HTMLImageElement>(null),
        "404": useRef<HTMLImageElement>(null),
        "601": useRef<HTMLImageElement>(null),
    };

    useEffect(() => {
        const img = document.getElementById("firstImageToFocuse") as HTMLImageElement | null;
        img?.focus();
    }, []);

    return (
        <Container>
            <div
                style={{
                    display: "flex",
                    marginTop: "30px",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: isMobile ? "" : "center",
                    alignItems: isMobile ? "center" : "",
                }}
            >
                {/* @ts-ignore */}
                <Label onDragOver={(e) => handleDragOver(e, "27")} onDrop={(e) => handleDrop(e, "27")}>
                    <Image
                        ref={imageRefs["27"]}
                        id="firstImageToFocuse"
                        src={
                            isDocumentUploaded("27")
                                ? `${themeIcon}/addPdf.png`
                                : draggingOverDocument === "27"
                                ? `${themeIcon}/dropPdf.png`
                                : `${themeIcon}/uploadDocumentPlaceholder.png`
                        }
                        onKeyDown={(e) => handleKeyPress(e, "27")}
                        tabIndex={0}
                        uploadedFile={isDocumentUploaded("27")}
                    />
                    <span>Пуномоћје</span>

                    {isDocumentUploaded("27") && (
                        <>
                            {getUploadedDocuments("27").map((document, index) => (
                                <div key={index} style={{ marginTop: "10px" }}>
                                    <FileName>{document.name}</FileName>
                                    <DeleteImage
                                        style={{ padding: "5px", borderRadius: "5px" }}
                                        src={`${themeIcon}/whiteDelete.png`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleDeleteDocument("27", document.name);
                                        }}
                                    />
                                    {document.isSigned ? (
                                        <div style={{ color: "green" }}>Потписан</div>
                                    ) : (
                                        <div style={{ color: "red" }}>Није електронски потписан</div>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                    <Input
                        id="fileInput-27"
                        type="file"
                        onChange={(e) => {
                            handleFileUpload(e.target.files, "27");
                        }}
                        accept=".pdf"
                        multiple={true}
                    />
                </Label>

                {/* @ts-ignore */}
                <Label onDragOver={(e) => handleDragOver(e, "401")} onDrop={(e) => handleDrop(e, "401")}>
                    <Image
                        ref={imageRefs["401"]}
                        src={
                            isDocumentUploaded("401")
                                ? `${themeIcon}/addPdf.png`
                                : draggingOverDocument === "401"
                                ? `${themeIcon}/dropPdf.png`
                                : `${themeIcon}/uploadDocumentPlaceholder.png`
                        }
                        onKeyDown={(e) => handleKeyPress(e, "401")}
                        tabIndex={0}
                        uploadedFile={isDocumentUploaded("401")}
                    />
                    <span>Исправа за упис/промену података у катастру</span>
                    {isDocumentUploaded("401") && (
                        <>
                            {getUploadedDocuments("401").map((document, index) => (
                                <div key={index} style={{ marginTop: "10px" }}>
                                    <FileName>{document.name}</FileName>
                                    <DeleteImage
                                        style={{ padding: "5px", borderRadius: "5px" }}
                                        src={`${themeIcon}/whiteDelete.png`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleDeleteDocument("401", document.name);
                                        }}
                                    />
                                    {document.isSigned ? (
                                        <div style={{ color: "green" }}>Потписан</div>
                                    ) : (
                                        <div style={{ color: "red" }}>Није електронски потписан</div>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                    <Input
                        id="fileInput-401"
                        type="file"
                        onChange={(e) => {
                            handleFileUpload(e.target.files, "401");
                        }}
                        accept=".pdf"
                        multiple={true}
                    />
                </Label>

                {/* @ts-ignore */}
                <Label onDragOver={(e) => handleDragOver(e, "405")} onDrop={(e) => handleDrop(e, "405")}>
                    <Image
                        ref={imageRefs["405"]}
                        src={
                            isDocumentUploaded("405")
                                ? `${themeIcon}/addPdf.png`
                                : draggingOverDocument === "405"
                                ? `${themeIcon}/dropPdf.png`
                                : `${themeIcon}/uploadDocumentPlaceholder.png`
                        }
                        onKeyDown={(e) => handleKeyPress(e, "405")}
                        tabIndex={0}
                        uploadedFile={isDocumentUploaded("405")}
                    />
                    <span>Захтев ( као основна исправа)</span>
                    {isDocumentUploaded("405") && (
                        <>
                            {getUploadedDocuments("405").map((document, index) => (
                                <div key={index} style={{ marginTop: "10px" }}>
                                    <FileName>{document.name}</FileName>
                                    <DeleteImage
                                        style={{ padding: "5px", borderRadius: "5px" }}
                                        src={`${themeIcon}/whiteDelete.png`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleDeleteDocument("405", document.name);
                                        }}
                                    />
                                    {document.isSigned ? (
                                        <div style={{ color: "green" }}>Потписан</div>
                                    ) : (
                                        <div style={{ color: "red" }}>Није електронски потписан</div>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                    <Input
                        id="fileInput-405"
                        type="file"
                        onChange={(e) => {
                            handleFileUpload(e.target.files, "405");
                        }}
                        accept=".pdf"
                        multiple={true}
                    />
                </Label>

                {/* @ts-ignore */}
                <Label onDragOver={(e) => handleDragOver(e, "404")} onDrop={(e) => handleDrop(e, "404")}>
                    <Image
                        ref={imageRefs["404"]}
                        src={
                            isDocumentUploaded("404")
                                ? `${themeIcon}/addPdf.png`
                                : draggingOverDocument === "404"
                                ? `${themeIcon}/dropPdf.png`
                                : `${themeIcon}/uploadDocumentPlaceholder.png`
                        }
                        onKeyDown={(e) => handleKeyPress(e, "404")}
                        tabIndex={0}
                        uploadedFile={isDocumentUploaded("404")}
                    />
                    <span>Захтев (као друга исправа)</span>
                    {isDocumentUploaded("404") && (
                        <>
                            {getUploadedDocuments("404").map((document, index) => (
                                <div key={index} style={{ marginTop: "10px" }}>
                                    <FileName>{document.name}</FileName>
                                    <DeleteImage
                                        style={{ padding: "5px", borderRadius: "5px" }}
                                        src={`${themeIcon}/whiteDelete.png`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleDeleteDocument("404", document.name);
                                        }}
                                    />
                                    {document.isSigned ? (
                                        <div style={{ color: "green" }}>Потписан</div>
                                    ) : (
                                        <div style={{ color: "red" }}>Није електронски потписан</div>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                    <Input
                        id="fileInput-404"
                        type="file"
                        onChange={(e) => {
                            handleFileUpload(e.target.files, "404");
                        }}
                        accept=".pdf"
                        multiple={true}
                    />
                </Label>

                {/* @ts-ignore */}
                <Label onDragOver={(e) => handleDragOver(e, "601")} onDrop={(e) => handleDrop(e, "601")}>
                    <Image
                        ref={imageRefs["601"]}
                        src={
                            isDocumentUploaded("601")
                                ? `${themeIcon}/addPdf.png`
                                : draggingOverDocument === "601"
                                ? `${themeIcon}/dropPdf.png`
                                : `${themeIcon}/uploadDocumentPlaceholder.png`
                        }
                        onKeyDown={(e) => handleKeyPress(e, "601")}
                        tabIndex={0}
                        uploadedFile={isDocumentUploaded("601")}
                    />
                    <span>Очитана лична карта странке</span>
                    {isDocumentUploaded("601") && (
                        <>
                            {getUploadedDocuments("601").map((document, index) => (
                                <div key={index} style={{ marginTop: "10px" }}>
                                    <FileName>{document.name}</FileName>
                                    <DeleteImage
                                        style={{ padding: "5px", borderRadius: "5px" }}
                                        src={`${themeIcon}/whiteDelete.png`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleDeleteDocument("601", document.name);
                                        }}
                                    />
                                    {document.isSigned ? (
                                        <div style={{ color: "green" }}>Потписан</div>
                                    ) : (
                                        <div style={{ color: "red" }}>Није електронски потписан</div>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                    <Input
                        id="fileInput-601"
                        type="file"
                        onChange={(e) => {
                            handleFileUpload(e.target.files, "601");
                        }}
                        accept=".pdf"
                        multiple={true}
                    />
                </Label>
            </div>
        </Container>
    );
};

export default AddDocumentsAdvokati;
