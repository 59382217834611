import styled from "styled-components";
import { themeColors, themeIcon } from "../../../../constants/constants";

export const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const KatastarElement = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const KatastarElementHeader = styled.div`
    display: flex;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    justify-content: left;
    align-items: center;
    height: 40px;
    padding-left: 20px;
    background-color: ${themeColors.secondary};
    color: ${themeColors.primary};
    @media screen and (max-width: 900px) {
        font-size: 14px;
    }
`;

export const KatastarElementBody = styled.div`
    background-color: ${themeColors.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
`;

export const KatastarElementBodyRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 20px;
    font-size: 16px;
    width: calc(100%-40px);
    color: ${themeColors.secondary};
    @media screen and (max-width: 900px) {
        font-size: 12px;
    }
`;

export const Key = styled.div`
    display: flex;
    align-items: flex-start;
    min-width: 250px;
    max-width: 250px;
    margin-right: 20px;
    font-weight: 600;
    word-wrap: break-word;

    @media screen and (max-width: 900px) {
        font-size: 12px;
        min-width: 100px;
        max-width: 100px;
    }
`;

export const Value = styled.div`
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    max-width: 600px;
    word-wrap: break-word;
    @media screen and (max-width: 900px) {
        font-size: 12px;
        min-width: 150px;
        max-width: 150px;
        margin-right: 0px;
    }
`;

export const ShowOnKatastarMap = styled.div`
    display: "flex";
    width: "34%";
    justify-content: "center";
    align-items: "center";
    cursor: "pointer";
`;

export const LocationIcon = styled.div`
    width: 50px;
    height: 50px;
    background-image: url(${themeIcon}/locationBold.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 0;
    cursor: pointer;
`;

export const LocationTransparentIcon = styled.div`
    margin-left: 10px;
    width: 25px;
    height: 25px;
    background-image: url(${themeIcon}/locationWhite.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 0;
    cursor: pointer;
`;

export const PDFIcon = styled.div`
    width: 100px;
    height: 100px;
    background-image: url(${themeIcon}/largePDF.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 0;
    cursor: pointer;
`;

export const PreviewPDFIFrame = styled.div`
    min-width: 1000px;
    max-width: 1700px;
    width: 1700px;
    height: "calc(90vh + 100px)";
    margin-top: "-30px";
    z-index: 1;
`;
