import { SetStateAction, useEffect, useState } from "react";
import { themeColors } from "../../../constants/constants";
import { vrstaUgovoraOptions, vrstaZahtevaAdvokatiOptions } from "../../../register/register";
import KatakomInput from "../../shared/KatakomInput";
import KatakomButton from "../../shared/KatakomButton";
import KatakomTable from "../../shared/KatakomTable";
import Loader from "../../shared/Loader";
import { Column, KatakomPaginate, Row } from "../style";
import KatakomModal from "../../shared/KatakomModal";
import StatusPreviewModal from "./StatusPreviewModal";
import api from "../../../services/api";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import useUserRole from "../../../hooks/useUserRole";
import useMobileCheck from "../../../hooks/useMobileChecker";
import { DataNotFound } from "../katastar/pretragaPoBrojuParcele/PretragaPoBrojuParaceleLayout";
import { useLocation } from "react-router-dom";
import { DopunaModal } from "./DopunaModal";
import KatakomModalNg from "../../shared/KatakomModalNg";
import { InfoMessageModal } from "../../customComponent/InfoMessageModal";
import { ROLE } from "../../../enums/role";
import { delay } from "@reduxjs/toolkit/dist/utils";

export const StatusSearch = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [brojPredmetaSuda, setBrojPredmetaSuda] = useState<string>("");
    const [brojPredmetaRgz, setBrojPredmetaRgz] = useState<string>(queryParams.get("brojPredmetaRgz") || "");
    const [upisnik, setUpisnik] = useState<string>("");
    const [imePodnosioca, setImePodnosioca] = useState<string>("");
    const [data, setData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [previewModal, setPreviewModal] = useState<boolean>(false);
    const [notAllowedModal, setNotAllowedModal] = useState<boolean>(false);
    const [dopunaModal, setDopunaModal] = useState<boolean>(false);
    const [dopunaCase, setDopunaCase] = useState<any>({});

    const isMobile = useMobileCheck();
    const { isAdvokat } = useUserRole();

    const itemsPerPage = 7;

    const fetchData = async (offset: number) => {
        setLoading(true);

        let queryParams: any = {};

        if (brojPredmetaSuda !== "") {
            queryParams.brojPredmetaSuda = brojPredmetaSuda;
        }

        if (brojPredmetaRgz !== "") {
            queryParams.brojPredmeta = brojPredmetaRgz;
        }

        if (upisnik !== "") {
            queryParams.upisnik = upisnik;
        }

        if (imePodnosioca !== "") {
            queryParams.imePodnosioca = imePodnosioca;
        }

        try {
            const res = await api.get(`api/v1/get-requests`, {
                ...queryParams,
                limit: itemsPerPage,
                offset,
            });

            const resData = res?.data?.data.map((item: { vrsta_isprave_id: string }) => {
                const foundOption = vrstaUgovoraOptions.find((el) => el.value == item.vrsta_isprave_id);
                let advokatOption;
                if (!foundOption) {
                    //@ts-ignore
                    advokatOption = vrstaZahtevaAdvokatiOptions.find((el) => el.value == String(item.vrsta_isprave_id));
                }
                return {
                    ...item,
                    vrsta_isprave_id: foundOption ? foundOption.label : advokatOption ? advokatOption?.label : "",
                };
            });

            setData(resData);
            setTotalPages(Math.ceil(res?.data.meta.total / itemsPerPage));
            setTotalRecords(res?.data.meta.total);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const currentPageNumber = Math.floor(currentPage * itemsPerPage);
        fetchData(currentPageNumber);
    }, [currentPage]);

    useEffect(() => {
        if (!imePodnosioca && !brojPredmetaRgz && !brojPredmetaSuda) {
            fetchData(currentPage);
        }
    }, [imePodnosioca, brojPredmetaRgz, brojPredmetaSuda]);

    const handlePageClick = (selected: { selected: SetStateAction<number> }) => {
        setCurrentPage(selected.selected);
    };

    const handleSearchClick = () => {
        fetchData(currentPage);
        setCurrentPage(0);
    };

    const role = useSelector((state: RootState) => state.user.role);

    const columns =
        role === ROLE.ADVOKAT
            ? [
                  { label: "Име подносиоца", value: "ucesnik" },
                  { label: "ЈМБГ/Матични број/Број пасоша", value: "jmbg/broj_pasosa/maticni_broj" },
                  { label: "Број предмета", value: "broj_predmeta" },
                  { label: "Врста исправе", value: "vrsta_isprave_id" },
                  { label: "Време слања", value: "datum_prijema" },
                  { label: "Статус", value: "status" },
                  {
                      label: "ПДФ",
                      value: "statusPreview",
                      func: () => {
                          setPreviewModal(true);
                      },
                  },
                  {
                      label: "Допуна предмета",
                      value: "addFile",
                      func: async (item: any) => {
                          const res = await api.get(
                              `/api/v1/check-if-additional-data-sending-is-allowed?idPrijemEsalter=${item.id_prijem_esalter}`
                          );
                          const isAllowed = res.data.isAllowed;
                          if (isAllowed) {
                              setDopunaModal(true);
                              setDopunaCase(item);
                          } else {
                              setNotAllowedModal(true);
                          }
                      },
                  },
              ]
            : [
                  { label: "Име подносиоца", value: "ucesnik" },
                  { label: "Број предмета", value: "broj_predmeta_suda" },
                  { label: "Врста исправе", value: "vrsta_isprave_id" },
                  { label: "Време слања", value: "datum_predmeta" },
                  { label: "Број предмета у РГЗ-у", value: "broj_predmeta" },
                  { label: "Статус", value: "status" },
                  {
                      label: "ПДФ",
                      value: "statusPreview",
                      func: () => {
                          setPreviewModal(true);
                      },
                  },
              ];

    return (
        <>
            <KatakomModal isOpen={notAllowedModal} setIsOpen={setNotAllowedModal}>
                <InfoMessageModal
                    closeModal={() => {
                        setNotAllowedModal(false);
                    }}
                    message="За овај предмет тренутно нису дозвољене допуне/жалбе!"
                />
            </KatakomModal>
            <KatakomModal isOpen={previewModal} setIsOpen={setPreviewModal}>
                <StatusPreviewModal
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                />
            </KatakomModal>
            <KatakomModalNg isOpen={dopunaModal} setIsOpen={setDopunaModal}>
                <DopunaModal
                    caseData={dopunaCase}
                    closeModal={() => {
                        setDopunaModal(false);
                    }}
                />
            </KatakomModalNg>
            <Column>
                <Column>
                    {isMobile ? (
                        <Column style={{ marginTop: "0px", alignItems: "center" }}>
                            <KatakomInput
                                type={"text"}
                                value={imePodnosioca}
                                name={"imePodnosioca"}
                                size="m"
                                label="Име подносиоца"
                                error={false}
                                onChange={(e) => {
                                    setImePodnosioca(e.target.value);
                                }}
                                onEnter={handleSearchClick}
                                style={{ margin: "20px 0 0 0" }}
                            />
                            <KatakomInput
                                type={"text"}
                                value={brojPredmetaSuda}
                                name={"brojPredmetaSuda"}
                                size="m"
                                label="Број предмета"
                                error={false}
                                onChange={(e) => {
                                    setBrojPredmetaSuda(e.target.value);
                                }}
                                onEnter={handleSearchClick}
                                style={{ margin: "20px 0 0 0" }}
                            />
                            {isAdvokat ? null : (
                                <KatakomInput
                                    type={"text"}
                                    value={brojPredmetaRgz}
                                    name={"brojPredmetaRgz"}
                                    size="m"
                                    label="Број предмета у РГЗ-у"
                                    error={false}
                                    onChange={(e) => {
                                        setBrojPredmetaRgz(e.target.value);
                                    }}
                                    onEnter={handleSearchClick}
                                    style={{ margin: "20px 0 0 0" }}
                                />
                            )}
                            <KatakomButton style={{ margin: "30px 0" }} size="xl" label={"ПРЕТРАЖИ"} onClick={handleSearchClick} />
                        </Column>
                    ) : (
                        <Row style={{ marginTop: "0px" }}>
                            <KatakomInput
                                type={"text"}
                                value={imePodnosioca}
                                name={"imePodnosioca"}
                                size="s"
                                label="Име подносиоца"
                                error={false}
                                onChange={(e) => {
                                    setImePodnosioca(e.target.value);
                                }}
                                onEnter={handleSearchClick}
                                style={{ margin: "20px 20px 0px 20px" }}
                            />
                            <KatakomInput
                                type={"text"}
                                value={brojPredmetaSuda}
                                name={"brojPredmetaSuda"}
                                size="s"
                                label="Број предмета"
                                error={false}
                                onChange={(e) => {
                                    setBrojPredmetaSuda(e.target.value);
                                }}
                                onEnter={handleSearchClick}
                                style={{ margin: "20px 20px 0px 20px" }}
                            />
                            {isAdvokat ? null : (
                                <KatakomInput
                                    type={"text"}
                                    value={brojPredmetaRgz}
                                    name={"brojPredmetaRgz"}
                                    size="m"
                                    label="Број предмета у РГЗ-у"
                                    error={false}
                                    onChange={(e) => {
                                        setBrojPredmetaRgz(e.target.value);
                                    }}
                                    onEnter={handleSearchClick}
                                    style={{ margin: "20px 20px 0px 20px" }}
                                />
                            )}

                            <KatakomButton label={"ПРЕТРАЖИ"} onClick={handleSearchClick} />
                        </Row>
                    )}

                    {loading ? (
                        <Loader />
                    ) : data.length > 0 ? (
                        <div style={{ margin: "15px", marginBottom: "50px" }}>
                            <KatakomTable columns={columns} data={data} onEdit={() => {}} onDelete={() => {}} />{" "}
                        </div>
                    ) : (
                        <DataNotFound>*Подаци нису пронађени</DataNotFound>
                    )}
                </Column>
                {data.length > 0 && (
                    <div style={{ marginTop: isMobile ? "20px" : "0px" }}>
                        <KatakomPaginate
                            previousLabel={isMobile ? "<<" : "Претходно"}
                            nextLabel={isMobile ? ">>" : `Следеће`}
                            breakLabel={"..."}
                            pageCount={totalPages}
                            marginPagesDisplayed={isMobile ? 1 : 2}
                            pageRangeDisplayed={isMobile ? 1 : 5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                        />
                        <span
                            style={{
                                position: "absolute",
                                left: isMobile ? "20px" : "50px",
                                bottom: isMobile ? "46px" : "26px",
                                color: themeColors.secondary,
                                fontSize: isMobile ? "14px" : "16px",
                            }}
                        >
                            Укупно: {totalRecords}
                        </span>
                    </div>
                )}
            </Column>
        </>
    );
};
