import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { themeColors } from "../../../constants/constants";

interface SnackbarProps {
    message: string;
    isVisible: boolean;
}

const SnackbarContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: ${themeColors.secondary};
    color: ${themeColors.primary};
    padding: 15px;
    visibility: ${(props: { isVisible: boolean }) => (props.isVisible ? "visible" : "hidden")};
    transition: visibility 0.7s, opacity 0.7s ease-out;
    opacity: ${(props: { isVisible: boolean }) => (props.isVisible ? "1" : "0")};
    z-index: 9999;
`;

const Snackbar = ({ message, isVisible }: SnackbarProps) => {
    const [show, setShow] = useState(isVisible);

    useEffect(() => {
        setShow(isVisible);
        const timer = setTimeout(() => {
            setShow(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, [isVisible]);

    return <SnackbarContainer isVisible={show}>{message}</SnackbarContainer>;
};

export default Snackbar;
