import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { themeColors } from "../../../constants/constants";
import { Tooltip } from "../../shared/KatakomButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { CaseState } from "../../../store/CaseReducer";
import { CaseTip2State } from "../../../store/CaseTip2Reducer";
import { CaseAdvokatiState } from "../../../store/CaseAdvokatiReducer";

interface ProgressBarProps {
    steps: string[];
    currentStep: number;
    onStepClick: (stepIndex: number) => void;
    validateStep: (index: number, slucaj: any) => { disabled: boolean; tooltipMessage: string };
    slucaj: CaseState | CaseTip2State | CaseAdvokatiState;
}

const ProgressBarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -2px;
`;

interface ProgressBarStepProps {
    isActive: boolean;
    alreadyActivated: boolean;
}

const ProgressBarStep = styled.div<ProgressBarStepProps>`
    flex: 1;
    text-align: center;
    background-color: ${(props) => (props.isActive || props.alreadyActivated ? `${themeColors.tertiary}` : "transparent")};
    color: ${(props) => (props.isActive || props.alreadyActivated ? `${themeColors.primary}` : `${themeColors.primary}`)};
    font-weight: 500;
    cursor: ${(props) => (props.isActive || props.alreadyActivated ? "pointer" : "default")};
    margin: ${(props) => (props.isActive || props.alreadyActivated ? "0 0 0 2px" : "0")};
    padding: 6px 0;
    pointer-events: ${(props) => (props.isActive || props.alreadyActivated ? "auto" : "none")};
    position: relative;

    &::before {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 100%;
        height: 6px;
        background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.3));
    }

    &:hover ${Tooltip} {
        opacity: 1;
    }
`;

const ProgressBar: React.FC<ProgressBarProps> = ({ steps, currentStep, slucaj, onStepClick, validateStep }) => {
    const [activatedSteps, setActivatedSteps] = useState<number[]>([]);

    useEffect(() => {
        if (currentStep > activatedSteps.length) {
            setActivatedSteps([...activatedSteps, currentStep]);
        }
    }, [currentStep]);

    return (
        <ProgressBarContainer>
            {steps.map((step, index) => {
                const isActive = index + 1 === currentStep;
                const isPerviouseStep = index + 1 < currentStep;
                const alreadyActivated = activatedSteps.includes(index + 1);
                const { disabled, tooltipMessage } = validateStep(index, slucaj);

                return (
                    <ProgressBarStep
                        key={step}
                        isActive={isActive}
                        alreadyActivated={alreadyActivated}
                        onClick={() => (!disabled || isPerviouseStep ? onStepClick(index + 1) : null)}
                    >
                        {step}
                        {disabled && !isPerviouseStep && index >= currentStep && <Tooltip>{tooltipMessage}</Tooltip>}
                    </ProgressBarStep>
                );
            })}
        </ProgressBarContainer>
    );
};

export default ProgressBar;
