import React, { SetStateAction, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { ROLE } from "../../../enums/role";
import { RowWrapper } from "../katastar/pretragaPoImenu/PretragaPoImenuLayout";
import KatakomInput from "../../shared/KatakomInput";
import KatakomButton from "../../shared/KatakomButton";
import KatakomTable from "../../shared/KatakomTable";
import UserModal from "./userModal";
import useMobileCheck from "../../../hooks/useMobileChecker";
import api from "../../../services/api";
import * as XLSX from "xlsx";
import moment from "moment";
import { KatakomPaginate } from "../style";
import UserStatisticsModal from "./userSearchModal";

export interface User {
    korisnik_id: number;
    email: string;
    first_name: string;
    last_name: string;
    type: string;
    is_active: boolean;
    office_id: string;
    theme_id: string;
    auto_colapse: boolean;
    is_2fa_enabled: boolean;
    mb: string;
    counter?: number;
    yearly_counter?: number;
}

const UserManagementList: React.FC = () => {
    const [userSearch, setUserSearch] = useState("");
    const [users, setUsers] = useState<User[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const isMobile = useMobileCheck();
    const [isUserModalOpen, setUserModalOpen] = useState(false);
    const [isStatisticsModalOpen, setStatisticsModalOpen] = useState(false);
    const [userData, setUserData] = useState<User | null>(null);
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const location = useLocation();
    const role = useSelector((state: RootState) => state.user.role);

    const openUserModal = () => setUserModalOpen(true);
    const closeUserModal = () => setUserModalOpen(false);

    const openStatisticsModal = () => setStatisticsModalOpen(true);
    const closeStatisticsModal = () => setStatisticsModalOpen(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const email = params.get("email");
        if (email) {
            setUserSearch(email);
            const currentPageNumber = Math.floor(currentPage * 10);
            getUsers(currentPageNumber, email);
            params.delete("email");
        } else {
            const currentPageNumber = Math.floor(currentPage * 10);
            getUsers(currentPageNumber);
        }
    }, [currentPage, location.search]);

    const getUsers = async (offset: number, searchValue?: string) => {
        try {
            let apiUrl = `api/v1/get-all-users?limit=10&offset=${offset}`;
            if (searchValue) {
                apiUrl += `&value=${encodeURIComponent(searchValue)}`;
            }
            const res = await api.get(apiUrl);
            const { data, meta } = res.data;

            // Fetch monthly and yearly search counts
            const countsRes = await api.get(`/api/v1/get-current-month-and-year-search-report`);
            const { monthly, yearly } = countsRes.data;

            // Merge monthly and yearly data
            const mergedUsers = data.map((user: User) => {
                const monthlyData = monthly.find((m: any) => m.email === user.email) || { counter: 0 };
                const yearlyData = yearly.find((y: any) => y.email === user.email) || { yearly_counter: 0 };
                return {
                    ...user,
                    counter: monthlyData.counter,
                    yearly_counter: yearlyData.yearly_counter,
                };
            });

            setUsers([...mergedUsers]);
            setTotalPages(Math.ceil(meta.total / 10));
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const getAllCounts = async () => {
        try {
            let apiUrl = `/api/v1/get-current-month-and-year-search-report`;

            const res = await api.get(apiUrl);
            const { monthly, yearly } = res.data;
            // Create a map for quick lookup of monthly data
            const monthlyMap = new Map(monthly.map((m: any) => [m.email, m]));

            const finalData = yearly.map((y: any) => ({
                Имејл: y.email,
                Име: y.first_name,
                Презиме: y.last_name,
                //@ts-ignore
                "Месечне претраге": monthlyMap.has(y.email) ? monthlyMap.get(y.email).counter : 0,
                "Годишње претраге": y.yearly_counter,
            }));

            finalData.sort(
                (a: { "Месечне претраге": any }, b: { "Месечне претраге": any }) =>
                    (b["Месечне претраге"] || 0) - (a["Месечне претраге"] || 0)
            );

            const worksheet = XLSX.utils.json_to_sheet(finalData);
            worksheet["!cols"] = [{ width: 50 }, { width: 30 }, { width: 30 }, { width: 20 }, { width: 20 }];

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Broj pretraga");
            XLSX.writeFile(workbook, `brojPretragaKorisnika_${moment().format("YYYY-MM-DD_HH-mm")}.xlsx`);
        } catch (error) {
            console.error("Error fetching user counts:", error);
        }
    };

    const handleActivateUser = async (email: string) => {
        try {
            const res = await api.post(`change-active-status`, { email });
        } catch (error) {
            console.error("Error changing user status:", error);
        }
    };

    const handleUserControl = async (user: User, action: string) => {
        if (action === "activate") {
            await handleActivateUser(user.email);
        } else if (action === "edit") {
            setUserData(user);
            openUserModal();
        } else if (action === "statistika") {
            setSelectedUserId(user.korisnik_id);
            openStatisticsModal();
        }
    };

    const handlePageClick = (selected: { selected: SetStateAction<number> }) => {
        setCurrentPage(selected.selected);
    };

    return (
        <div>
            <UserModal isOpen={isUserModalOpen} onClose={closeUserModal} userData={userData} />
            <UserStatisticsModal isOpen={isStatisticsModalOpen} onClose={closeStatisticsModal} userId={selectedUserId ?? 0} />
            {role === ROLE.ADMIN || role === ROLE.SUPERADMIN || role === ROLE.ADMIN_BANK ? (
                <div style={{ position: "absolute", right: "30px", top: "40px" }}>
                    <KatakomButton label={"Преузми све"} onClick={() => getAllCounts()} />
                </div>
            ) : null}

            <RowWrapper style={{ marginTop: "30px", alignItems: "center" }}>
                <KatakomInput
                    type={"text"}
                    value={userSearch}
                    name={"userSearch"}
                    size="xl"
                    placeholder="Претражите кориснике"
                    error={false}
                    onChange={(e) => {
                        setUserSearch(e.target.value);
                    }}
                    onEnter={() => {}}
                    style={{ margin: "0px 0 0 0" }}
                />
                <KatakomButton size="l" label={"ПРЕТРАЖИ"} onClick={() => getUsers(0, userSearch)} />
            </RowWrapper>

            <div style={{ marginBottom: " 60px" }}>
                <KatakomTable
                    columns={[
                        { label: "И-мејл", value: "email" },
                        { label: "Име", value: "first_name" },
                        { label: "Презиме", value: "last_name" },
                        { label: "Статус", value: "is_active" },
                        { label: "Месечне претраге", value: "counter" },
                        { label: "Годишње претраге", value: "yearly_counter" },
                        {
                            label: "",
                            value: "userControl",
                            func: (e, v) => handleUserControl(e, v),
                        },
                    ]}
                    data={users}
                />
            </div>
            {users?.length > 0 && (
                <KatakomPaginate
                    previousLabel={isMobile ? "<<" : "Претходно"}
                    nextLabel={isMobile ? ">>" : `Следеће`}
                    breakLabel={"..."}
                    pageCount={totalPages}
                    marginPagesDisplayed={isMobile ? 1 : 2}
                    pageRangeDisplayed={isMobile ? 1 : 5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                />
            )}
        </div>
    );
};

export default UserManagementList;
