import styled from "styled-components";
import { themeColors } from "../../../constants/constants";
import { SettingsSidebarData } from "./SettingsSidebarData";
import { useSidebar } from "./SidebarContext";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { useRef } from "react";

interface SidebarLinkProps {
    sidebar: boolean;
    color?: "primary" | "secondary";
    content?: string;
}

const SidebarNav = styled.nav<SidebarLinkProps>`
    position: fixed;
    top: 80px;
    left: 0;
    background: ${({ color }) => (color === "secondary" ? `${themeColors.tertiary}` : `${themeColors.secondary}`)};
    width: ${({ sidebar }) => (sidebar ? "400px" : "60px")};
    color: ${themeColors.primary};
    height: calc(100vh - 80px);
    overflow-y: auto;
    white-space: nowrap;
    box-shadow: 4px 4px 4px 0px #00000040;
    transition: width ease-in-out 500ms;
    overflow-x: hidden;
    z-index: 1;
`;
const SidebarWrap = styled.div`
    width: 100%;
`;

const Sidebar: React.FC<SidebarLinkProps> = ({ color, content }) => {
    const { sidebar } = useSidebar();
    const email = localStorage.getItem("email");
    let settingsSidebarData = SettingsSidebarData;
    const sidebarRef = useRef(null);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        //@ts-ignore
        const sidebarLinks = Array.from(sidebarRef.current?.querySelectorAll("a") || []);

        const focusedIndex = sidebarLinks.indexOf(document.activeElement as HTMLAnchorElement);

        if (e.key === "ArrowDown") {
            e.preventDefault();
            const nextIndex = focusedIndex === sidebarLinks.length - 1 ? 0 : focusedIndex + 1;
            //@ts-ignore
            sidebarLinks[nextIndex].focus();
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            const prevIndex = focusedIndex === 0 ? sidebarLinks.length - 1 : focusedIndex - 1;
            //@ts-ignore
            sidebarLinks[prevIndex].focus();
        }
    };

    return (
        <SidebarNav ref={sidebarRef} sidebar={sidebar} color={color} onKeyDown={handleKeyDown}>
            <SidebarWrap>
                {content === "settings"
                    ? settingsSidebarData.map((item, index) => {
                          let tabIndex = 130;
                          return <SubMenu key={index} item={item} customColor={color} tabIndex={tabIndex++} />;
                      })
                    : SidebarData.map((item, index) => {
                          let tabIndex = 100;
                          return <SubMenu key={index} item={item} customColor={color} tabIndex={tabIndex++} />;
                      })}
            </SidebarWrap>
        </SidebarNav>
    );
};

export default Sidebar;
