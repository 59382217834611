import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { themeColors } from "../../../../../constants/constants";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import KatakomButton from "../../../../shared/KatakomButton";
import { useSidebar } from "../../../../widgets/sidebar/SidebarContext";
import { ButtonGroup, Card, CardContainer, CardHeader, CardTitle, CardWrapper, LayoutWraper } from "../../../style";
import { RenderDokumenta } from "./RenderDokumenta";
import { RenderNepokretnosti } from "./RenderNepokretnosti";
import { RenderOsnovniPodaci } from "./RenderOsnovniPodaci";
import { RenderPodnosioci } from "./RenderPodnosioci";
import KatakomModal from "../../../../shared/KatakomModal";
import { SuccessMessageModal } from "../../../../customComponent/SuccessMessageModal";
import { ErrorMessageModal } from "../../../../customComponent/ErrorMessageModal";
import { addDocumentAdvokati, resetState } from "../../../../../store/CaseAdvokatiReducer";
import { WaitingModal } from "../../../../customComponent/WaitingModal";
import api from "../../../../../services/api";

const PreviewLayout: React.FC = () => {
    const slucaj = useSelector((state: RootState) => state.caseAdvokati);
    const tempDokumenta = useSelector((state: RootState) => state.caseAdvokati.tempDokumenta);
    const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
    const [waitingModalOpen, setWaitingModalOpen] = useState<boolean>(false);
    const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);

    const dispatch: AppDispatch = useAppDispatch();

    let navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    };

    const submitFiles = async () => {
        setWaitingModalOpen(true);
        const documents = [];
        try {
            for (const dok of tempDokumenta) {
                const response = await api.post(`api/v1/send-file-from-lawyers`, {
                    base64Document: dok.file,
                });
                documents.push({ id_prijem_fajl_esalter: response?.data.id_prijem_fajl_esalter, vrstaIspraveId: dok.vrstaIspraveId });
            }

            dispatch(addDocumentAdvokati(documents));
        } catch (e) {
            setErrorModalOpen(true);
        } finally {
            setWaitingModalOpen(false);
        }
    };

    const handleFinish = async () => {
        const slucajWithoutId = {
            ...slucaj,
        };
        slucajWithoutId.ucesnici = slucajWithoutId.ucesnici.map(createNewObjectWithoutId);
        slucajWithoutId.nepokretnostKN = slucajWithoutId.nepokretnostKN.map(createNewObjectWithoutId);
        slucajWithoutId.tempDokumenta = slucajWithoutId.tempDokumenta.map(createNewObjectWithoutId);

        try {
            const res = await api.post(`api/v1/send-data-from-lawyers`, {
                ...slucajWithoutId.basicInfo,
                ucesnici: slucajWithoutId.ucesnici,
                nepokretnostKN: slucajWithoutId.nepokretnostKN,
                dokumenta: slucajWithoutId.dokumenta,
            });

            if (res.status !== 200) {
                setErrorModalOpen(true);
            } else {
                setSuccessModalOpen(true);
            }
        } catch (e) {
            setErrorModalOpen(true);
        }
    };

    useEffect(() => {
        if (slucaj.dokumenta.length > 0) {
            handleFinish();
        }
    }, [slucaj.dokumenta]);

    const { sidebar } = useSidebar();

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <KatakomModal isOpen={successModalOpen} setIsOpen={setSuccessModalOpen}>
                <SuccessMessageModal
                    closeModal={() => {
                        routeChange("/pocetna");
                        dispatch(resetState());
                        setSuccessModalOpen(false);
                    }}
                    message="Успешно сте послали захтев!"
                />
            </KatakomModal>

            <KatakomModal isOpen={waitingModalOpen} setIsOpen={setWaitingModalOpen}>
                <WaitingModal message="Захтев се шаље молимо вас сачекајте!" />
            </KatakomModal>

            <KatakomModal isOpen={errorModalOpen} setIsOpen={setErrorModalOpen}>
                <ErrorMessageModal
                    closeModal={() => {
                        routeChange("/pocetna");
                        dispatch(resetState());
                        setErrorModalOpen(false);
                    }}
                    message="Дошло је до грешке приликом слања захтева!"
                />
            </KatakomModal>

            <CardWrapper>
                <Card style={{ minHeight: "10vh" }}>
                    <CardContainer>
                        <CardHeader>
                            <CardTitle>ПРЕГЛЕД</CardTitle>
                        </CardHeader>
                        <PreviewTitle>oсновни подаци</PreviewTitle>
                        <RenderOsnovniPodaci />
                    </CardContainer>
                </Card>
            </CardWrapper>

            <CardWrapper>
                <Card style={{ minHeight: "10vh" }}>
                    <CardContainer>
                        <PreviewTitle>непокретности</PreviewTitle>
                        <RenderNepokretnosti />
                    </CardContainer>
                </Card>
            </CardWrapper>

            <CardWrapper>
                <Card style={{ minHeight: "10vh" }}>
                    <CardContainer>
                        <PreviewTitle>странке</PreviewTitle>
                        <RenderPodnosioci />
                    </CardContainer>
                </Card>
            </CardWrapper>

            <CardWrapper>
                <Card style={{ minHeight: "10vh" }}>
                    <CardContainer>
                        <PreviewTitle>документа</PreviewTitle>
                        <RenderDokumenta />
                    </CardContainer>
                </Card>
            </CardWrapper>

            <ButtonGroup>
                <KatakomButton
                    size="l"
                    label={"Назад"}
                    onClick={() => {
                        routeChange("/noviPredmetAdvokati");
                    }}
                />
                <KatakomButton
                    size="l"
                    label={"Пошаљи"}
                    onClick={() => {
                        if (slucaj.tempDokumenta.length > 0) {
                            submitFiles();
                        } else {
                            handleFinish();
                        }
                    }}
                />
            </ButtonGroup>
        </LayoutWraper>
    );
};

export const createNewObjectWithoutId = (obj: any) => {
    const newObj = { ...obj };
    delete newObj.id;
    return newObj;
};

export default PreviewLayout;

const PreviewTitle = styled.div`
    display: flex;
    font-size: 20px;
    font-weight: 500;
    padding: 0 0 0 30px;
    align-items: center;
    background-color: ${themeColors.secondary};
    color: ${themeColors.primary};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: calc(100% -30px);
    height: 36px;
`;
