import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { themeColors, themeIcon } from "../../constants/constants";
import { CaseTip2State } from "../../store/CaseTip2Reducer";
import moment from "moment";

const styles = StyleSheet.create({
    document: {
        flexDirection: "column",
        backgroundColor: themeColors.primary,
        fontFamily: "Montserrat",
        fontWeight: 400,
        lineHeight: 1.5,
    },
    header: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    headerRepublikaSrbija: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "60%",
    },
    headerSignature: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "70%",
        marginLeft: "50%",
    },
    headerPotpisMinistra: {
        marginTop: 30,
    },
    headerText: {
        fontSize: 12,
        textAlign: "center",
        marginTop: 0,
        fontWeight: 500,
    },
    header2Text: {
        fontSize: 12,
        textAlign: "center",
        marginTop: 20,
        marginBottom: 20,
        fontWeight: 500,
    },
    page: {
        flexDirection: "column",
        fontSize: 12,
        backgroundColor: themeColors.primary,
        padding: 40,
    },
    body: {
        display: "flex",
        fontSize: 12,
        flexDirection: "column",
        alignItems: "center",
        marginTop: 30,
    },
    boldTitle: {
        fontWeight: 600,
        fontSize: 16,
        marginTop: 50,
    },
    boldText: {
        fontWeight: 500,
        fontSize: 12,
        marginBottom: 20,
        textAlign: "center",
    },
    boldJustifyText: {
        fontWeight: 500,
        fontSize: 12,
        marginBottom: 20,
        textAlign: "justify",
    },
    boldData: {
        fontWeight: 500,
        fontSize: 12,
    },
    centerText: {
        textAlign: "center",
        fontWeight: 400,
    },
    justifyText: {
        textAlign: "justify",
        fontWeight: 400,
    },
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Regular.ttf`,
    fontWeight: 400,
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Medium.ttf`,
    fontWeight: 500,
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-SemiBold.ttf`,
    fontWeight: 600,
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Bold.ttf`,
    fontWeight: 700,
});

interface GeneratePDFTip2Props {
    pdfData: CaseTip2State;
}

const GeneratePDFTip2: React.FC<GeneratePDFTip2Props> = ({ pdfData }) => {
    return (
        <Document style={styles.document}>
            <Page style={styles.page}>
                <View style={styles.headerRepublikaSrbija}>
                    <Image style={{ width: "120px" }} src={`${themeIcon}/grbSrbije.png`} />
                    <Text style={styles.headerText}>
                        Република Србија
                        {"\n"}
                        АГЕНЦИЈА ЗА ПРОСТОРНО ПЛАНИРАЊЕ И{"\n"}
                        УРБАНИЗАМ РЕПУБЛИКЕ СРБИЈЕ
                        {"\n"}
                    </Text>
                    <Text style={styles.centerText}>
                        Број: <Text style={styles.boldData}>{pdfData.basicInfo.brojPotvrde}</Text>
                        {"\n"}
                        Датум: <Text style={styles.boldData}>{moment(pdfData.basicInfo.datumPotvrde).format("DD.MM.YYYY.")}</Text> године
                        {"\n"}
                        Београд, Краља Милутина 10а
                    </Text>
                </View>
                <View style={styles.body}>
                    <Text style={styles.justifyText}>
                        На основу члана 102. став 8. Закона о планирању и изградњи (Службени гласник РС", бр. 72/09, 81/09 - исправка, 64/10
                        - УС, 24/11, 121/12, 42/13 - УС, 50/13 - УС, 98/13 - УС, 132/14, 145/14, 83/18, 31/19, 37/19 - др. закон и 9/20,
                        52/21, 62/23),и Информације о локацији број{" "}
                        <Text style={styles.boldData}>{pdfData.basicInfo.informacijeOLokacijiBroj}</Text>
                        {"\n"}
                        {"\n"}
                        Агенција за просторно планирање и урбанизам Републике Србије издаје
                    </Text>
                    <Text style={styles.boldTitle}>ПОТВРДУ</Text>
                    <Text style={styles.boldText}>
                        о испуњености услова за претварање права коришћена у право својине на непокретностима без накнаде и упис у јавни
                        регистар о евиденцији непокретности и правима на њима
                    </Text>
                    <Text style={styles.justifyText}>
                        Овим се потврђује да{" "}
                        {pdfData.nepokretnostKN.map((nepokretnost) => {
                            return (
                                <Text>
                                    к.п. број{" "}
                                    <Text style={styles.boldData}>
                                        {nepokretnost.brojParcele}
                                        {nepokretnost.podBrojParcele ? `/${nepokretnost.podBrojParcele}` : null}
                                    </Text>
                                    , површине <Text style={styles.boldData}>{nepokretnost.povrsina}м2</Text>, удео{" "}
                                    <Text style={styles.boldData}>{nepokretnost.udeo}</Text>. Све у К.О.{" "}
                                    <Text style={styles.boldData}>{nepokretnost.koNaziv}</Text>,{" "}
                                </Text>
                            );
                        })}{" "}
                        К.П. испуњавају услове у складу са одредбама члана 102. Закона о планирању и изградњи, те се на наведеним
                        катастарским парцелама може уписати право својине на грађевинском земљишту у корист
                        <Text style={styles.boldData}>
                            {pdfData.ucesnici[0].ime
                                ? " " + pdfData.ucesnici[0].ime + " " + pdfData.ucesnici[0].prezime
                                : " " + pdfData.ucesnici[0].imePravnogLica}
                        </Text>
                        из <Text style={styles.boldData}>{pdfData.ucesnici[0].opstinaNaziv}</Text>, матични број:{" "}
                        <Text style={styles.boldData}>
                            {pdfData?.ucesnici?.[0]?.jmbg ? pdfData?.ucesnici?.[0]?.jmbg : pdfData?.ucesnici?.[0]?.maticniBroj}.
                        </Text>
                    </Text>
                </View>

                <View wrap={false} style={styles.headerPotpisMinistra}>
                    <View style={styles.header}>
                        <Text style={styles.justifyText}>
                            Потврда се издаје на захтев{" "}
                            <Text style={styles.boldData}>
                                {pdfData.ucesnici[0].ime
                                    ? " " + pdfData.ucesnici[0].ime + " " + pdfData.ucesnici[0].prezime
                                    : " " + pdfData.ucesnici[0].imePravnogLica}
                            </Text>{" "}
                            за претварање права коришћења у право својине на грађевинском земљишту без накнаде и упис у јавни регистар о
                            евиденцији непокретности и правима на њима у корист
                            <Text style={styles.boldData}>
                                {pdfData.ucesnici[0].ime
                                    ? " " + pdfData.ucesnici[0].ime + " " + pdfData.ucesnici[0].prezime
                                    : " " + pdfData.ucesnici[0].imePravnogLica}
                            </Text>
                            , и не може се употребити у друге сврхе.
                            {"\n"}
                            Поврду доставити надлежној служби за катастар непокретности преко Е-шалтера.
                        </Text>
                    </View>
                    <View style={styles.headerSignature}>
                        <Text style={styles.header2Text}>
                            Потпис
                            {"\n"}
                        </Text>
                        <Text style={styles.centerText}>_______________________ {"\n"}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default GeneratePDFTip2;
