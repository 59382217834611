import { ROLE } from "../../../enums/role";

export interface SidebarItem {
    title: string;
    path: string;
    icon: string;
    permissions: ROLE[];
    subNav?: SidebarSubItem[];
}

interface SidebarSubItem {
    title: string;
    path: string;
    icon: string;
}

export const SettingsSidebarData: SidebarItem[] = [
    {
        title: "Промени лозинку",
        path: "/podesavanja/promeniLozinku",
        icon: "password",
        permissions: [],
    },
    {
        title: "Креирај нови налог",
        path: "/podesavanja/kreirajNalog",
        icon: "kreiraj",
        permissions: [],
    },
    // {
    //     title: "Подешавања",
    //     path: "/podesavanja/podesavanjaNaloga",
    //     icon: "podesavanja",
    //     permissions: [ROLE.ROLE_IZVRSITELJ],
    // },
];
