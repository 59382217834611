import React, { useEffect, useState } from "react";
import { LiceCard, Person } from "./LiceCard";
import styled from "styled-components";
import { RenderLicaData } from "../renderKatastarData/RenderLicaData";
import { themeColors } from "../../../../constants/constants";

interface RenderLicaCardsProps {
    data: Person[];
    setOpenedCard: (value: boolean) => void;
    liceNepokretnostiData: any;
    setLiceNepokretnostiData: (value: any) => void;
}

const Button = styled.button`
    display: flex;
    border-radius: 5px;
    font-size: 16px;
    background-color: ${themeColors.quaternary};
    color: ${themeColors.secondary};
    padding: 15px 25px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 25px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RenderLicaCards: React.FC<RenderLicaCardsProps> = ({
    data,
    setOpenedCard,
    liceNepokretnostiData,
    setLiceNepokretnostiData,
}) => {
    useEffect(() => {}, [liceNepokretnostiData]);

    return (
        <React.Fragment>
            {liceNepokretnostiData ? (
                <Wrapper>
                    <RenderLicaData data={liceNepokretnostiData} />
                </Wrapper>
            ) : (
                <CardWrapper>
                    {data.map((person, index) => (
                        <LiceCard
                            key={index}
                            person={person}
                            setLiceNepokretnostiData={setLiceNepokretnostiData}
                            setOpenedCard={setOpenedCard}
                        />
                    ))}
                </CardWrapper>
            )}
        </React.Fragment>
    );
};

export const CardWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 20px;
    margin-left: 20px;
    margin-top: 50px;
    margin-bottom: 100px;
`;
