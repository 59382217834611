import { ChangeEvent, FC } from "react";
import styled from "styled-components";
import { themeColors, themeIcon } from "../../constants/constants";
import { getSize } from "../../utils/getSize";

interface DatePickerProps {
    value: string;
    name: string;
    label?: string;
    error: boolean;
    size?: "s" | "m" | "l" | "xl";
    disabled?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    style?: React.CSSProperties;
}

const DatePickerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 12px 6px 16px 0;
`;

const InputField = styled.div`
    position: relative;
`;

const Input = styled.input<DatePickerProps>`
    font-size: 16px;
    font-weight: 400;
    width: ${(props) => getSize(props.size)};
    color: ${themeColors.secondary};
    background: ${themeColors.primary};
    border: none;
    box-shadow: none;
    outline: none;
    border-radius: 4px;
    padding: 15px 15px;
    box-shadow: 5px 5px 10px 0px #00000040;
    height: 19px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::placeholder {
        font-size: 16px;
        font-weight: 400;
        color: ${themeColors.secondary};
    }

    &::-webkit-calendar-picker-indicator {
        background: url(${themeIcon}/calendar.png);
        background-size: 20px;
    }
`;

const Label = styled.label`
    font-size: 15px;
    font-weight: 400;
    color: ${themeColors.secondary};
    height: 40px;
`;

const Error = styled.p`
    color: ${themeColors.tertiary};
    font-size: 16px;
    font-weight: 400;
    margin-left: 12px;
    margin-top: 4px;
`;

const DatePicker: FC<DatePickerProps> = ({ value, name, label, error, disabled, onChange, style, size }) => {
    const inputSize = getSize(size);

    return (
        <DatePickerWrapper style={{ ...style }}>
            <Label htmlFor={name}>{label}</Label>
            <InputField>
                <Input
                    type="date"
                    id={name}
                    value={value}
                    name={name}
                    error={error}
                    onChange={onChange}
                    disabled={disabled}
                    style={{ width: inputSize }}
                />
            </InputField>
            {error && <Error>Обавезно поље!</Error>}
        </DatePickerWrapper>
    );
};

export default DatePicker;
