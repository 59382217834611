import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { themeColors } from "../../constants/constants";

const Loader = () => {
    const [loaderPosition, setLoaderPosition] = useState({ top: "50vh", left: "50%" });

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

            const top = `${50 + scrollTop / 2}vh`;
            const left = `${50 + scrollLeft / 2}%`;

            setLoaderPosition({ top, left });
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <LoaderWrapper style={{ top: loaderPosition.top, left: loaderPosition.left }}>
            <LoaderAnimation />
        </LoaderWrapper>
    );
};

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const LoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 100000;
`;

const LoaderAnimation = styled.div`
    border: 4px solid ${themeColors.quinary};
    border-top: 4px solid ${themeColors.quaternary};
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: ${spin} 1.2s linear infinite;
`;

export default Loader;
