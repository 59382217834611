import { ChangeEvent } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import KatakomTable from "../../../../shared/KatakomTable";
import { addTempDocument, deleteTempDocument } from "../../../../../store/CaseReducer";
import { themeColors } from "../../../../../constants/constants";
import { Container, Row } from "../../../style";
import api from "../../../../../services/api";

const Label = styled.label`
    font-size: 16px;
    font-weight: 500;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin: 30px 0 0 0;
    width: 200px;
    background-color: ${themeColors.secondary};
    color: white;
    box-shadow: 5px 5px 10px 0px #00000040;
    border-radius: 4px;
    cursor: pointer;
    transition: border 0.3s;

    input[type="file"] {
        display: none;
    }
`;

const Input = styled.input``;

type Props = {
    setCurrentStep: () => void;
};

const AddDocuments: React.FC<Props> = ({ setCurrentStep }) => {
    const dispatch: AppDispatch = useAppDispatch();
    const tempDokumenta = useSelector((state: RootState) => state.case.tempDokumenta);

    const handleDeleteDocument = (dokument: any) => {
        dispatch(deleteTempDocument(dokument.id));
    };

    // Function to handle file selection
    const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;
        if (selectedFiles) {
            const newFiles = Array.from(selectedFiles).filter((file) => file.type === "application/pdf");
            await Promise.all(
                newFiles.map((file) => {
                    return new Promise<string>(async (resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = async () => {
                            const base64String = reader.result?.toString().split(",")[1];
                            if (base64String) {
                                try {
                                    const isSigned = await hasDigitalSignature(base64String);
                                    dispatch(
                                        addTempDocument({
                                            name: file.name,
                                            file: base64String,
                                            isSigned: Boolean(isSigned),
                                            category: "Kategorija 1",
                                        })
                                    );
                                    resolve(base64String);
                                } catch (error) {
                                    reject(error);
                                }
                            } else {
                                reject(new Error("Error while reading file"));
                            }
                        };
                        reader.onerror = () => {
                            reject(new Error("Error while reading file"));
                        };
                    });
                })
            );
        }
    };

    const hasDigitalSignature = async (base64EncodedFile: string) => {
        const response = await api.post(`api/v1/has-digital-signature`, {
            base64Document: base64EncodedFile,
        });

        return response?.data;
    };

    const columns = [
        { label: "Ид", value: "id" },
        { label: "Документ", value: "name" },
        { label: "Категорија документа", value: "category" },
        { label: "Електронски потпис", value: "isSigned" },
        { label: "", value: "delete" },
    ];

    return (
        <Container>
            {tempDokumenta.length !== 0 ? (
                <div style={{ margin: "15px" }}>
                    <KatakomTable columns={columns} data={tempDokumenta} onDelete={handleDeleteDocument} />
                </div>
            ) : null}

            <Row style={{ display: "flex", justifyContent: "center" }}>
                <Label>
                    Додај фајл
                    <Input type="file" onChange={handleFileUpload} accept=".pdf" multiple />
                </Label>
            </Row>
        </Container>
    );
};

export default AddDocuments;
