import styled from "styled-components";
import { themeColors } from "./constants/constants";

const StyledSiteUnderMaintenance = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
`;

const Message = styled.p`
    margin: 10px 0;
    font-size: 24px;
    font-weight: 500;
    color: ${themeColors.tertiary};
    @media screen and (max-width: 900px) {
        font-size: 20px;
    }
    text-align: center;
`;

const MessageTitle = styled.p`
    margin: 10px 0;
    font-size: 34px;
    font-weight: 600;
    color: ${themeColors.tertiary};
    text-align: center;
    @media screen and (max-width: 900px) {
        font-size: 30px;
    }
`;

const Email = styled.p`
    margin: 10px 0;
    font-size: 26px;
    font-weight: 600;
    color: ${themeColors.tertiary};
    text-align: center;
    @media screen and (max-width: 900px) {
        font-size: 22px;
    }
`;

export const SiteUnderMaintenance = () => {
    return (
        <StyledSiteUnderMaintenance>
            <Message>Ups...</Message>
            <MessageTitle>Sajt je na odrzavanju!</MessageTitle>
            <div style={{ margin: "80px 0" }}>
                <img src={`${process.env.PUBLIC_URL}/maintanance.png`} alt="Site under maintenance" />
            </div>
            <Message>Izvinite na smetnji,</Message>
            <Message>mozete nas kontaktirati putem mejla</Message>
            <Email>info@katakom.rs</Email>
        </StyledSiteUnderMaintenance>
    );
};
