import styled from "styled-components";
import { themeColors } from "../../constants/constants";
import ReactPaginate from "react-paginate";

export const LayoutWraper = styled.div<{ sidebarOpen: Boolean }>`
    display: flex;
    flex-direction: column;
    background-color: ${themeColors.quaternary};
    margin-top: 80px;
    width: ${(props) => (props.sidebarOpen ? "calc(100% - 400px)" : "calc(100% - 60px)")};
    margin-left: ${(props) => (props.sidebarOpen ? "400px" : "60px")};
    transition: width 500ms ease-in-out, margin-left 500ms ease-in-out;

    @media screen and (max-width: 900px) {
        transition: width 200ms ease-out, margin-left none;
    }
`;

export const CardWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 20px;
    @media screen and (max-width: 900px) {
        margin: 10px;
    }
`;

export const Card = styled.div`
    min-width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${themeColors.primary};
    min-height: calc(100vh - 120px);
    align-items: center;
    box-shadow: 5px 5px 10px 0px #00000040;
    border-radius: 5px;
    outline: none;
    border: none;
    position: relative;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 100px;
    width: 100%;
`;

export const CardTitle = styled.h1`
    font-size: 32px;
    color: ${themeColors.secondary};
    font-weight: 800;
    margin: 80px 0px 50px 25px;
    @media screen and (max-width: 900px) {
        font-size: 22px;
        margin: 60px 0px 30px 25px;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    min-height: calc(100vh - 380px);
`;

export const ButtonGroup = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 60px);
    margin: 10px 30px 20px 30px;

    & > *:first-child {
        margin-right: 15px;
    }

    & > *:not(:first-child):not(:last-child) {
        margin-left: 15px;
        margin-right: 15px;
    }

    & > *:last-child {
        margin-left: 15px;
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: end;
    flex-direction: row;
    width: 100%;
    margin: 14px 5px;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 30px 50px;
    overflow: visible;
`;

export const Column = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const KatakomPaginate = styled(ReactPaginate).attrs({
    activeClassName: "active",
})`
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    padding: 0 5rem;
    width: 50%;

    li a {
        border-radius: 7px;
        padding: 0.1rem 0.7rem;
        border: ${themeColors.secondary} 1px solid;
        cursor: pointer;
    }
    li.previous a,
    li.next a,
    li.break a {
        border-color: transparent;
    }
    li.active a {
        background-color: ${themeColors.secondary};
        border-color: transparent;
        color: ${themeColors.primary};
        min-width: 24px;
    }
    li.disabled a {
        color: ${themeColors.secondary};
    }
    li.disable,
    li.disabled a {
        cursor: default;
    }
    @media screen and (max-width: 900px) {
        font-size: 14px;
        bottom: 0;
        left: calc(50% - 30px);
    }
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
    margin-right: 20px;
    margin-left: 5px;
    padding: 9px;
    appearance: none;
    background-color: transparent;
    border-radius: 4px;
    position: relative;
    box-shadow: 5px 5px 10px 0px #00000040;

    &:before {
        content: "";
        position: absolute;
        border-radius: 4px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: ${themeColors.secondary};
        opacity: ${(props) => (props.checked ? 1 : 0)};
    }

    &:focus {
        background-color: ${themeColors.quaternary};
        opacity: 0.5;
    }
`;
